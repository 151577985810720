@font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 300;
     src: url(../font/Montserrat/Montserrat-Light.woff) format('woff');
     font-display: swap;
}
@font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 400;
     src: url(../font/Montserrat/Montserrat-Regular.woff) format('woff');
     font-display: swap;
}
@font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 500;
     src: url(../font/Montserrat/Montserrat-Medium.woff) format('woff');
     font-display: swap;
}
@font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 600;
     src: url(../font/Montserrat/Montserrat-SemiBold.woff) format('woff');
     font-display: swap;
}
@font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 700;
     src: url(../font/Montserrat/Montserrat-Bold.woff) format('woff');
     font-display: swap;
}
@font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 800;
     src: url(../font/Montserrat/Montserrat-ExtraBold.woff) format('woff');
     font-display: swap;
}
@font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 900;
     src: url(../font/Montserrat/Montserrat-Black.woff) format('woff');
     font-display: swap;
}