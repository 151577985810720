@mixin xs() {
  @media screen and (max-width: $device-xs) {
    @content;
  }
}

@mixin sm() {
	@media screen and (min-width: $device-xs) and (max-width: $device-sm) {
		@content;
	}
}


@mixin md() {
	@media screen and (min-width: $device-sm) and (max-width: $device-md) {
		@content;
	}
}


@mixin lg() {
  @media screen and (min-width: $device-md) and (max-width: $device-lg) {
		@content;
	}
}


@mixin xl() {
  @media screen and (min-width: $device-lg) {
    @content;
  }
}


@mixin sm-lte( ) {
	@media screen and (max-width: $device-sm) {
		@content;
	}
}

@mixin md-lte( ) {
	@media screen and (max-width: $device-md) {
		@content;
	}
}

@mixin lg-lte( ) {
	@media screen and (max-width: $device-lg) {
		@content;
	}
}

@mixin xs-gt( ) {
	@media screen and (min-width: $device-xs) {
		@content;
	}
}

@mixin sm-gt( ) {
	@media screen and (min-width: $device-sm) {
		@content;
	}
}

@mixin md-gt( ) {
	@media screen and (min-width: $device-md) {
		@content;
	}
}




@mixin device-above( $device-size ) {
	@media screen and (min-width: $device-size) {
		@content;
	}
}

@mixin device-below( $device-size ) {
	@media screen and (max-width: $device-size) {
		@content;
	}
}


@mixin device( $device-min, $device-max ) {
	@media screen and (min-width: $device-min) and (max-width: $device-max) {
		@content;
	}
}