.service {
	.serviceList {
		background: #fff;
		margin-bottom: 1em;
		// border: 1px solid #eae9e5;
		padding: 10px 10px calc(10px - 1%) 10px;
		.column {
			&_box {
				display: flex;
				align-items: center;
				margin-bottom: 1%;
				width: calc(99% / 2);
				padding: 10px 20px;
				border-radius: 5px;
				@include fz(14);

				@include xs {
					@include fz(12);
					width: 100%;
					margin-bottom: 5px;
				}

				.img {
					width: 50px;
					height: auto;
					margin-right: 10px;
					
					@include xs {
						width: 40px;
					}
				}
			}
		}

		.service {
			&__on {
				background: #c9dae9;
				// background: #e5e5e5;
				color: $blue02;
				font-weight: bold;

				&> br {
					display: none;
				}
			}

			&__off {
				background: #f1f1f1;
				color: #a0a0a0;
				img {
					opacity: .5;
				}

				&> br {
					display: none;
				}
			}

		}
	}

	// &plan__box {
	// 	background: #f5f5f5;
	// 	padding: 20px 20px 30px 20px;
	// 	position: relative;
	// 	margin: 0 0 2em;
	// 	&::before {
	// 		content: "";
	// 		display: block;
	// 		position: absolute;
	// 		top: 0;
	// 		left: calc(50% - 40px);
	// 		width: 80px;
	// 		height: 1px;
	// 		background: $blue01;
	// 		margin: 0 auto;
	// 	}
	// 	h3 {
	// 		background: transparent;
	// 		color: $blue02;
	// 		@include fz(22);
	// 		font-weight: 500;
	// 		text-align: center;
	// 		padding: 0;

	// 		@include xs {
	// 			@include fz(17);
	// 			letter-spacing: 0;
	// 			margin: 0 0 1em;
	// 		}
	// 	}

	// 	table {
	// 		margin: 0;
	// 		td {
	// 			background: #ffffff;
	// 		}
	// 	}
	// }

	.partner {
		&__wrapper {
			padding: 20px 20px 0;
			text-align: center;
			p {
				margin-bottom: .6em;
				@include fz(13);
			}
		}
		&__block {
			justify-content: center;
		}
	}
}

// サービスindex
.service {
	&-plan__stack {
		background: $sub-color;
		padding: 3em;
		border-radius: 15px;

		h3 {
			background: transparent;
			text-align: center;
			color: $main-color;
			@include ffz(20, 16);
			padding: 0;
		}
	}
}




// サービス詳細ページ
.service {
	&-navigation {
		background: #f5f5f5;
		padding: 3em;
		margin: 4em 0 0;

		@include xs {
			padding: 2em;
		}

		&-list {
			margin: 0;

			&__item {
				@include ffz(16, 15);
				background: #fff;
				border-radius: 5px;

				&::before {
					content: "";
					display: none;
				}
			}
		}

		&__link {
			padding: 1em;
			display: block;

			&::before {
				counter-increment: ol_list;
				content: counter(ol_list,decimal-leading-zero) ".";
				color: $base-text-color;
				font-family: Montserrat,sans-serif;
				font-weight: 500;
				text-indent: -2em;
				display: inline-block;
				width: 0;
			}
		}
		
		.title__h2 {
			@include ffz(16, 15);
			border-width: 0 0 1px 0;
			border-color: #ccc;
			padding: 0 0 0 !important;
		}
	}




	&-fee {
		background: lighten($blue02, 0%);
		padding: 1.5em 3em 3em;
		border-radius: $border-radius;
		margin: 4em 0 0;
		
		&__title {
			background: transparent;
			margin: 0 0 .5em;
			padding: 0;
			text-align: center;
			color: #fff;
		}

		&__body {
			background: #fff;
			border-radius: $border-radius;
			padding: 2em 3em;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include xs {
				flex-direction: column;
				text-align: center;
				padding: 2em;
			}
		}

		&-detail {
			margin: 0;
			@include fz(18);
			color: $blue01;
			font-weight: bold;

			@include xs {
				margin: 0 0 1em;
			}

			p {
				margin: 0;

				&.caution {
					color: $base-text-color;
					font-weight: normal;
				}
			}

			&__tax {
				@include fz(12);
			}
		}

		&-btn {
			@include xs {
				width: 100%;
				text-align: center;
			}
		}

		&__link {
			display: block;
			// background: $blue01;
			background: #aaa;
			color: #fff;
			padding: 1em 3em 1em 2em;
			@include fz(14);
			position: relative;

			&:hover {
				background: $blue01;
				color: #fff;
			}


			&::after {
				@include arrow_r;
				position: absolute;
				right: 1em;
			}
		}
	}

	
}

