/*
* Noto Sans Japanese (japanese) http://www.google.com/fonts/earlyaccess
 */


// @font-face {
//   font-family: 'Noto Sans Japanese';
//   font-style: normal;
//   font-weight: 100;
//   src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Thin.woff2) format('woff2'),
//        url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Thin.woff) format('woff'),
//        url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Thin.otf) format('opentype');
// }

// @font-face {
//   font-family: 'Noto Sans Japanese';
//   font-style: normal;
//   font-weight: 200;
//   src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Light.woff2) format('woff2'),
//        url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Light.woff) format('woff'),
//        url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Light.otf) format('opentype');
// }

// @font-face {
//    font-family: 'Noto Sans Japanese';
//    font-style: normal;
//    font-weight: 300;
//    src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff2) format('woff2'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff) format('woff'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.otf) format('opentype');
// }

// @font-face {
//    font-family: 'Noto Sans Japanese';
//    font-style: normal;
//    font-weight: 400;
//    src: local('NotoSansJP-Regular.otf'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.woff2) format('woff2'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.woff) format('woff'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.otf) format('opentype');
//  }

// @font-face {
//    font-family: 'Noto Sans Japanese';
//    font-style: normal;
//    font-weight: 500;
//    src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.woff2) format('woff2'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.woff) format('woff'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.otf) format('opentype');
//  }

// @font-face {
//    font-family: 'Noto Sans Japanese';
//    font-style: normal;
//    font-weight: 700;
//    src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff2) format('woff2'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff) format('woff'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.otf) format('opentype');
//  }

// @font-face {
//    font-family: 'Noto Sans Japanese';
//    font-style: normal;
//    font-weight: 900;
//    src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.woff2) format('woff2'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.woff) format('woff'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.otf) format('opentype');
//  }



@font-face {
     font-family: 'Noto Sans Japanese';
     font-style: normal;
     font-weight: 100;
     src: url(../font/Noto_Sans_JP/NotoSansJP-Thin.woff) format('woff'),
          url(../font/Noto_Sans_JP/NotoSansJP-Thin.otf) format('opentype');
     font-display: swap;
}
@font-face {
     font-family: 'Noto Sans Japanese';
     font-style: normal;
     font-weight: 200;
     src: url(../font/Noto_Sans_JP/NotoSansJP-Light.woff) format('woff'),
          url(../font/Noto_Sans_JP/NotoSansJP-Light.otf) format('opentype');
     font-display: swap;
}
// @font-face {
//      font-family: 'Noto Sans Japanese';
//      font-style: normal;
//      font-weight: 300;
//      src: url(../font/Noto_Sans_JP/NotoSansJP-DemiLight.woff) format('woff'),
//           url(../font/Noto_Sans_JP/NotoSansJP-DemiLight.otf) format('opentype');
//      font-display: swap;
// }

@font-face {
   font-family: 'Noto Sans Japanese';
   font-style: normal;
   font-weight: 300;
   src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff2) format('woff2'),
        url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff) format('woff'),
        url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.otf) format('opentype');
}

@font-face {
     font-family: 'Noto Sans Japanese';
     font-style: normal;
     font-weight: 400;
     src: url(../font/Noto_Sans_JP/NotoSansJP-Regular.woff) format('woff'),
          url(../font/Noto_Sans_JP/NotoSansJP-Regular.otf) format('opentype');
     font-display: swap;
}
@font-face {
     font-family: 'Noto Sans Japanese';
     font-style: normal;
     font-weight: 500;
     src: url(../font/Noto_Sans_JP/NotoSansJP-Medium.woff) format('woff'),
          url(../font/Noto_Sans_JP/NotoSansJP-Medium.otf) format('opentype');
     font-display: swap;
}
@font-face {
     font-family: 'Noto Sans Japanese';
     font-style: normal;
     font-weight: 700;
     src: url(../font/Noto_Sans_JP/NotoSansJP-Bold.woff) format('woff'),
          url(../font/Noto_Sans_JP/NotoSansJP-Bold.otf) format('opentype');
     font-display: swap;
}
@font-face {
     font-family: 'Noto Sans Japanese';
     font-style: normal;
     font-weight: 900;
     src: url(../font/Noto_Sans_JP/NotoSansJP-Black.woff) format('woff'),
          url(../font/Noto_Sans_JP/NotoSansJP-Black.otf) format('opentype');
     font-display: swap;
}
