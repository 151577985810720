@charset "UTF-8";
@import "_variables";

.success_case_index  {
	margin-bottom: 50px;


	&-member {
		margin: 0 0 8rem;

		@include xs {
			margin: 0 0 4rem;
		}

		&__box {
			width: 100%;

			a {
				display: block;
				// padding: 30px;
				border-top: 1px solid #ccc;
				color: $base-text-color;
				margin: 0 0 3em;


				&:hover {
					background: #f5f5f9;
					opacity: 1;
					animation: none;

					// .success_case__hero {
					// 	outline: 2px solid #fff;
					// }
				}
			}
		}

		.success_case__hero {
			text-align: center;
			margin: 2em 0 0;

			img {
				width: 100%;
			}
		}



		&-table {
			width: 100%;
			
			&__wrapper {

				@include xs {
					width: 100%;
					padding: 0;
				}
			}
			
			@include ffz(15, 13);
			
			thead {
				tr {
					th {
						padding: 0.8em 1.5em;
						@include ffz(13, 12);
					}

				}

				@include xs {
					display: none;
				}
			}

			tbody {

				tr {
					@include xs {
						&:last-child {
							td:last-child {
								border-bottom: 1px solid #e0dfd9;
							}
						}
					}
				}
				
				th {
					padding: 0.8em 1.5em;
					@include ffz(15, 13);

					@include xs {
						padding: 0;
						background: $sub-color;

						&::before {
							content: attr(data-label);
							padding: 0.8em 1.5em;
							display: inline-block;
							background: #eae9e5;
							margin-right: 1em;
							width: 25%;
						}
					}
				}
				td {
					padding: 0.8em 1.5em;
					@include ffz(15, 13);

					@include xs {
						padding: 0;
						background: #fff;
						display: flex;
						align-items: center;

						&::before {
							content: attr(data-label);
							padding: 0.8em 1.5em;
							display: inline-block;
							background: lighten(#eae9e5, 4%);
							margin-right: 1em;
							width: 25%;
						}


						&:last-child {
							border: 0;
						}
					}
				}
			}
		}
	}

	

	.success_case {

		
		&__box {
			&__wrapper {
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
	
				@include sm-lte {
					flex-direction: column;
					justify-content: center;
				}
			}


			// width: calc(50% - 40px);
			width: 50%;

			// &:nth-child(2n) {
			// 	margin-left: 80px;
			// }

			@include sm-lte {
				width: 100%;
			}


			a {
				padding: 40px;
				height: 100%;
			}



			a {
				display: block;
				padding: 50px 30px;
				border-top: 1px solid #ccc;
				color: $base-text-color;


				@include xs {
					padding: 30px 30px;
				}
				&:hover {
					background: #f5f5f9;
					opacity: 1;
					animation: none;

					.success_case__hero {
						outline: 2px solid #fff;
					}
				}
			}


			h2 {
				// @include fz(26);
				// font-weight: bold;
				// text-align: center;
				border: 0;
				padding: 0;
				margin: 0;


				// color: $blue03;
				@include xs {
					margin: 0 auto 2rem;
					img {
						// height: 60px;
						width: auto;
						&.row3 {
							height: 88px;
						}
					}
				}
			}

			p {
				@include ffz(15, 14);
			}
		}

		&-title {
			@include ffz(18, 16);
			letter-spacing: .05em;
			text-align: left;

			&__name {
				margin-bottom: 1em;
				display: inline-block;
				@include ffz(16, 14);
			}

			&__marker {
				@include ffz(13, 11);
				color: #fff;
				background: $blue01;
				margin: 0 0 0.6em;
				// padding: 0 0 0.5em 0;
				padding: .3em .8em;
				letter-spacing: .05em;
				display: inline-block;
				font-weight: normal;
				// border-bottom: 2px solid $blue01;
				// color: $blue01;
			}

			&__achievement {
				display: block;
				line-height: 1.4;
			}
		}
		
		&__hero {
			text-align: center;
			margin: 2em 0 2em;
			border: 0;

			img {
				width: 100%;
				height: auto;
			}
		}

		&__tag {
			margin-top: 20px;
			ul {
				// text-align: center;
				color: $blue03;
				margin-bottom: 0;
				li {
					padding: 0;
					margin: 0 5px 0 0;
					@include fz(10);


					@include xs {
						display: inline-block;
						@include fz(10);
					}
				}
			}
		}
	}
}


.success_case,
.success-case-template-default {

	&_hero {
		figcaption {
			margin: 10px 0 0;
			color: #666;
			p {
				@include fz(12);
			}
		}
	}

	.interview_block_list {
		a {
			p {
				margin-bottom: 10px;
			}
			p:last-child {
				margin-bottom: 0;
			}
			ul.tag {
				line-height: 1;
				&:last-child {
					margin-bottom: 0;
				}
				li {
					color: $blue01;
					letter-spacing: 0;
					padding: 0;
					&:before {
						content: "#";
					}
					&:last-child {
						margin-bottom: 0;
					}

					&.interview {
						display: none;
					}
				}
			}
		}
	}

	.ph_block {
		// display: inline-block;
		margin-bottom: 30px;
		text-align: center;
		display: inherit;

		@include xs {
			margin-bottom: 15px;
		}
	}

	.performance_box {
		.ex_list {
			border: 3px solid #c5c5c5;
			padding: 20px;
			margin-bottom: 30px;
			text-align: center;
			@include xs {
				border: 2px solid #c5c5c5;
				padding: 15px;
				margin-bottom: 15px;
			}
		}
		p {
			@include ffz(18, 16);
			margin-bottom: 10;
			font-weight: 300;
			@include ff_montserrat;
			margin-bottom: 0;
			// @include xs {
			// 	@include fz(14);
			// }
			b {
				@include fz(22);
				font-weight: 500;
				@include xs {
					@include fz(16);
					font-weight: 600;
				}
			}
		}

		span {
			@include xs {
				@include fz(12);
			}
		}
	}

	.talk__lead {
		@include fz(22);
		text-align: center;
		color: $blue02;
		@include yumin;
		font-weight: bold;
		position: relative;
		margin-top: 3em;

		@include xs {
			@include fz(19);
			margin: 5em 0 3em 0;
		}

		&::before {
			content: "";
			display: inline-block;
			background: $blue02;
			width: 100%;
			height: 1px;
			margin-right: 20px;
			position: absolute;
			top: 50%;
			left: 0;
			z-index: -1;

			@include xs {
				width: 1px;
				height: calc(100% + 60px);
				top: -30px;
				left: 50%;
			}
		}

		span {
			background: #fff;
			padding: 0 15px;
			position: relative;
			z-index: 10;
			display: inline-block;

			@include xs {
				padding: 10px 0;
			}
		}

		& + h2.ttl_interview_q {
			margin-top: 2em;
		}
	}

	.interview_block {
		margin-bottom: 80px;
		@include xs {
			margin-bottom: 40px;
		}
	}

	.person_name {
		color: $green;
	}
}

.interview_block {
	margin-bottom: 50px;
	@include xs {
		margin-bottom: 30px;
	}

	.column_block {
		display: block;
		overflow: auto;
	
		.float_r {
			float: right;
			display: inline;
			margin-left: 4%;
			margin-bottom: 10px;
			max-width: 46%;
	
			@include xs {
				float: none;
				display: block;
				margin-left: 0;
				width: 100%;
				max-width: 100%;
				text-align: center;
			}
		}
	
		.float_l {
			float: left;
			display: inline;
			margin-right: 4%;
			margin-bottom: 10px;
			max-width: 46%;
	
			@include xs {
				float: none;
				display: block;
				margin-left: 0;
				width: 100%;
				max-width: 100%;
				text-align: center;
			}
		}
	}
}


.single-success-case {
	.wp_social_bookmarking_light,
	.wp_social_bookmarking_light_clear {
		display: none;
	}
}

.interview {

	&-introduction {
		&__inner {
			display: flex;
			// justify-content: space-between;
			justify-content: stretch;
			gap: 20px;
			flex-wrap: wrap;
			background: lighten($blue01, 40%);
			padding: 20px;

			@include sm-lte {
				flex-direction: column;
			}
		}
	}
	
	&-introduction-company {
		// width: calc(50% - 10px);
		// border: solid #ccc;
		// border-width: 1px;
		flex: 1;
		padding: 20px 20px 30px 20px;
		background: #ffffff;

		@include xs {
			width: 100%;
			margin: 0 0 10px;
		}

		&__link {
			display: block;
			color: $base-text-color;
		}
		&__title {
			background: none;
			padding: 0;
			margin: 0 0 .5em;
			text-align: center;
		}

		p {
			@include fz(12);
			margin-bottom: 1em;
		}
	}

	&-introduction-company__btn__wrapper {
		display: flex;
		gap: 20px;
		justify-content: center;

		@include sm-lte {
			flex-direction: column;
			gap: 10px;
		}
	}

	&-introduction-company__btn {
		display: inline-block;
		background: #000;
		color: #fff;
		padding: 10px 40px 10px 20px;
		position: relative;
		@include fz(12);

		@include sm-lte {
			padding: 15px 40px 15px 20px;
		}

		&__wrapper {
			text-align: center;
		}
		
		&.__blank {
			&::after {
				content: "\f35d";
				font-family: $icon_fa;
				position: absolute;
				right: 10px;
				@include fz(11);
			}
		}
	}
}

.success_case-member {

	&__lead {
		margin: 0 0 4rem;
	}

	&__stack {
		margin: 0 0 8rem;
	}

	&-figure {
		margin: 3rem 0 3rem;
	}

	&-profile {
		&__inner {
			display: flex;

			@include xs {
				flex-direction: column;
			}
		}

		&__stack {
			width: calc(33.3% - 20px);
			// background: $sub-color;

			padding: 30px;
			border-radius: 10px;;
			border: 1px solid $blue03;

			@include sm {
				width: calc(33.3% - 7.5px);
				padding: 2rem;
			}

			@include xs {
				width: 100%;
				margin: 0 0 2rem;
				padding: 2rem;
			}

			&:not(:nth-child(3n)) {
				margin: 0 40px 0 0;

				@include sm {
					margin: 0 15px 0 0;
				}

				@include xs {
					margin: 0 0 2rem;
				}
			}
		}

		&__figure {
			text-align: center;
			margin: 0 0 2rem;

			@include xs {
				width: 40%;
				margin: 0 auto 2rem;
			}
		}

		&__title {
			letter-spacing: .08em;
			padding: 0 !important;
			border: 0;
			text-align: center;
		}

		&__title__job {
			@include ffz(14, 12);
			display: block;
		}

		&__detail {
			& > *:last-child {
				margin-bottom: 0;
			}
		}

		p {
			@include ffz(13, 12);
		}
	}

}