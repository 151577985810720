article,
.article {
	@import 'article-format';
	@import 'gutenberg_custom';
}

.article {
	&-list {
		&-cat {
			display: flex;
			flex-wrap: wrap;
			margin: 10px 0 0;
		}

		&-cat__item {
			padding: 0;
			margin-right: 10px;
			display: inherit;
			letter-spacing: 0;
			font-family: Montserrat,sans-serif;
			@include fz(11);
			color: $blue01;

			&::before {
				content: "#";
				margin-left: 0;
			}

			@include xs {
				@include fz(10);
			}
		}
	}
}