//----------------------------------------------------
/*! Type: YakuHanJPs_Narrow - Based on Noto Sans CJK JP */
//----------------------------------------------------

$yakuhanjps-narrow-font-family: "YakuHanJPs_Narrow" !default;
$yakuhanjps-narrow-font-file: "YakuHanJPs" !default;
$yakuhanjps-narrow-font-dir: "../font" !default;
$yakuhanjps-narrow-swap-use: true !default;
$yakuhanjps-narrow-eot-use: true !default;
$yakuhanjps-narrow-cdn-use: false !default;
$yakuhanjps-narrow-cdn-version: "3.3.1" !default;
$yakuhanjps-narrow-unicode-range-use: true !default;
$yakuhanjps-narrow-unicode: U+3008-3011, U+3014-3015, U+ff08-ff09, U+ff3b,
  U+ff3d, U+ff5b, U+ff5d !default;
$yakuhanjps-narrow-weight: (
  100: "Thin",
  200: "Thin",
  300: "Light",
  400: "Light",
  500: "DemiLight",
  600: "Regular",
  700: "Medium",
  800: "Bold",
  900: "Bold"
) !default;

@each $key, $value in $yakuhanjps-narrow-weight {
  @font-face {
    $family: $yakuhanjps-narrow-font-family;
    $file: $yakuhanjps-narrow-font-file;
    $dir: $yakuhanjps-narrow-font-dir;
    $swap: $yakuhanjps-narrow-swap-use;
    $eot: $yakuhanjps-narrow-eot-use;
    $cdn: $yakuhanjps-narrow-cdn-use;
    $version: $yakuhanjps-narrow-cdn-version;
    $jsdelivr: "https://cdn.jsdelivr.net/npm/yakuhanjp";
    $unicode-range: $yakuhanjps-narrow-unicode-range-use;
    $unicode: $yakuhanjps-narrow-unicode;

    font-family: $family;
    font-style: normal;
    font-weight: $key;

    @if $swap == true {
      font-display: swap;
    }

    @if $eot == true and $cdn == true {
      src: url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.eot");
    }

    @if $eot == true and $cdn == false {
      src: url("#{$dir}/#{$file}/#{$file}-#{$value}.eot");
    }

    @if $cdn == true {
      src: url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.woff2")
          format("woff2"),
        url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.woff")
          format("woff");
    } @else {
      src: url("#{$dir}/#{$file}/#{$file}-#{$value}.woff2") format("woff2"),
        url("#{$dir}/#{$file}/#{$file}-#{$value}.woff") format("woff");
    }

    @if $unicode-range == true {
      unicode-range: $unicode;
    }
  }
}
