
/* ============================================ */
/* mixin */
/* ============================================ */
@mixin fz($size, $base: 10) {
    font-size: $size + px;
    // font-size: calc( calc($size / $base)) * 1rem;
    font-size: calc( calc($size / $base) * 1rem);
}


$xs-vw: 576;
$lg-vw: 1200;

@mixin ffz($max-font-size, $min-font-size) {
  font-size: calc( #{$min-font-size}px + (#{$max-font-size} - #{$min-font-size}) / (#{$lg-vw} - #{$xs-vw}) * (100vw - #{$xs-vw}px) );
  min-height: 0vw;

  @include xs {
    font-size: $min-font-size + px;
    font-size: calc($min-font-size / 10) * 1rem;
  }

  @include md-gt {
    font-size: $max-font-size + px;
    font-size: calc($max-font-size / 10) * 1rem;
  }

}


$design-kamp: 1200; // 基準となるビューポート

@function px--vw($num, $width: $design-kamp) {
  @return calc(1vw * $num / $width * 100);
}




@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}


@mixin sp_content() {
	width: 100%;
	padding: 0 7%;
}

/* ============================================ */
/* effect */
/* ============================================ */

// flash -----------------------
@mixin flash() {
  opacity: 1;
  -webkit-animation: flash 1.3s;
  animation: flash 1.3s;
  @-webkit-keyframes flash {
    0% {
      opacity: .3;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes flash {
    0% {
      opacity: .3;
    }
    100% {
      opacity: 1;
    }
  }
}



/* ============================================ */
/* Icon */
/* ============================================ */
@mixin icon {
	font-family: "Ionicons";
}

@mixin arrow_r {
	content: "\f3d1";
	font-family: "Ionicons";
}

@mixin ionicon {
	font-family: "Ionicons";
	font-style: normal;/* 斜体を戻す */
}