.__module {
	.talk {
		&__block {
			margin: 3em 0 3em;
			.conversation {
				flex: 1;
				background: #f2f2f2;
				padding: 2em;
				border-radius: 10px;
				position: relative;
				
				@include ffz(18, 16);
				@include xs {
					padding: 20px;
				}

				p {
					@include ffz(18, 16);
					@include xs {
						@include fz(15);
					}
				}

				p:first-child {
					margin-top: 0;
				}
				p:last-child {
					margin-bottom: 0;
				}

			}
		}

		&__box {
			margin: 0 0 3em;
		}

		.ttl_interview_q {
			padding-right: 0;
			@include xs {
				@include fz(15);
				line-height: 1.5;
				padding: 0 0 0 20px;

				&:before {
					margin-left: -20px;
					width: 20px;
				}
			}
		}

		h2.ttl_interview_q {
			// width: 500px;
			margin: 4em auto 1.5em;
			border: 0;
			padding: 0 0 0 60px !important;
			@include ffz(28, 24);
			// text-align: center;
			@include yumincho;
			line-height: 1.5;
			position: relative;
			letter-spacing: .03em;

			@include xs {
				width: 100%;
				padding: 0 0 0 40px !important;
			}

			&::before {
				content: "Q";
				position: absolute;
				left: 0;
				@include fz(24);
				// @include yumincho;
				font-weight: normal;
				margin: 0;
				background: $blue02;
				color: #ffffff;
				width: 40px;
				height: 40px;
				text-align: center;
				@include xs {
					@include fz(20);
					width: 30px;
					height: 30px;
				}
			}

		}

		.thumb_l {
			display: flex;
			padding: 0 110px 0 0;

			@include sm-lte {
				padding: 0;
			}

			.thumbnail {
				width: 80px;
				@include xs {
					width: 55px;
				}
			}
			.conversation {
				margin: 0 0 0 30px;
				background: #e3e9f3;
				// display: flex;
				// align-items: center;
				@include xs {
					margin: 0 0 0 20px;
				}
				&:after {
					right: 100%;
					top: 1.5em;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					border-right-color: #e3e9f3;
					border-width: 10px;
					margin-top: -10px;

					@include xs {
						top: 25px;
						border-width: 10px;
						margin-top: -10px;
					}
				}

			}
		}

		.thumb_r {
			display: flex;
			padding: 0 0 0 110px;

			@include sm-lte {
				padding: 0;
			}

			.thumbnail {
				width: 80px;
				@include xs {
					width: 55px;
				}
			}
			.conversation {
				margin: 0 30px 0 0;
				@include xs {
					margin: 0 20px 0 0;
				}
				&:after {
					left: 100%;
					top: 1.5em;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					border-left-color: #f2f2f2;
					border-width: 10px;
					margin-top: -10px;
					@include xs {
						top: 25px;
						border-width: 10px;
						margin-top: -10px;
					}
				}
			}
		}
	}
}