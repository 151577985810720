@charset "UTF-8";

// contact_form

.contact {
	&_greeting__inner {
		display: flex;
		align-items: center;
		margin: 0 0 40px;
		padding: 20px;
		border: 1px solid #ccc;

		@include xs {
			display: block;
		}
		img {
			margin-right: 20px;
			width: 120px;
			height: auto;

			@include xs {
				width: 80px;
				margin: 0 auto 1em;
				display: block;
			}
		}

		ul:last-child {
			margin: 0;
		}
	}

	&_form {
		margin: 0 0 40px;
		padding: 40px;
		background: #f7f7f7;

		@include xs {
			padding: 30px 15px;
			margin: 0 0 2em;
		}
		h3.contact_form_step__title {
			background: transparent;
			padding: 0 !important;
			margin: 0 0 1em;
			@include fz(16);

			@include xs {
				@include fz(14);
				margin: 0 0 1em;
				letter-spacing: .05em;
			}


			span {
				background: $blue03;
				color: #fff;
				margin: 0 1em 0 0;
				padding: 0 3px;

				@include xs {
					display: block;
					width: 80px;
					margin: 0 0 0.3em;
				}
			}
		}
	}

	&_form__table {
		margin: 0 0;
		tbody {
			tr {
				th {
					@include xs {
						border-width: 1px 0 0;
					}
				}
				td {
					background: #fff;
					@include fz(13);
					@include xs {
						border-width: 0;
						&:last-child {
							border-width: 0;
						}
					}

				}

				@include xs {
					&:last-child {
						td {
							border-width: 0 0 1px;
						}
					}
				}
			}
		}
	}


	input {
		-webkit-appearance: none !important;
		background: #eae9e5;
		border: 1px solid #ccc;
		padding: 5px;
		@include fz(15);

		&.input_type01 {
			width: 300px !important;
			@include xs {
				width: 100% !important;
				padding: 10px;
				background: #ffffff;
			}
		}

		&.input_type02 {
			width: 100% !important;
			@include xs {
				width: 100% !important;
				padding: 10px;
				background: #ffffff;
			}
		}

		&:focus {
			background: #eae9e5;
		}

		&.wpcf7-not-valid {
			background: lighten($red, 35%);
		}
	}

	textarea {
		background: #eae9e5;
		border: 1px solid #ccc;
		padding: 5px;
		@include fz(15);
		width: 100% !important;			
	}

	p {
		margin-bottom: 10px;
	}

	.checkbox {

		span.wpcf7-list-item {
			display: block;
			@include xs {
				margin: 0 0 5px;
			}
		}

		label {
			padding: 5px 0;
		}

		input[type=checkbox] {
			display: none;
		}

		input[type=checkbox] + span {
			padding-left: 30px;
			position: relative;
			vertical-align: top;
			@include xs {
				border: 1px solid #ccc;
				display: block;
				padding: 10px 40px;
				text-decoration: none;
				border-radius: 5px;
			}

			&::before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 13px;
				height: 13px;
				border: 1px solid #ccc;
				background: #eee;

				@include xs {
					margin-left: 15px;
					top: 50%;
					transform: translate(0, -50%);
				}
			}

		}
		input[type=checkbox]:checked + span {
			color: #000000;
	
			@include xs {
				background: #f5f5f5;
			}

			&::before {
				background: #aaa;
				border-color: #aaa;
			}
	
			&::after {
				content: "";
				display: block;
				position: absolute;
				top: 2px;
				left: 4px;
				width: 4px;
				height: 6px;
				transform: rotate(40deg);
				border-bottom: 2px solid #fff;
				border-right: 2px solid #fff;
				@include xs {
					top: 50%;
					left: 15px;
					width: 6px;
					height: 8px;
					margin-top: -3px;
					transform: rotate(40deg) translate(0, -50%);
				}
			}
		}

		
	}



	ul.ckeck_list {
		li {
			margin-bottom: 10px;
			input[type=checkbox] {
				display: none;
			}

			input[type=checkbox] + label {
				padding-left: 30px;
				position: relative;
				vertical-align: top;
				@include xs {
					border: 1px solid #ccc;
					display: block;
					padding: 10px 40px;
					text-decoration: none;
				}
				
				&::before {
					content: "";
					display: block;
					position: absolute;
					top: 4px;
					left: 0;
					width: 13px;
					height: 13px;
					border: 1px solid #ccc;
					background: #eee;

					@include xs {
						margin-left: 15px;
						top: 50%;
						transform: translate(0, -50%);
					}
				}

			}

			input[type=checkbox]:checked + label {
				color: #000000;

				@include xs {
					background: #e5e5e5;
				}

				&::after {
					content: "";
					display: block;
					position: absolute;
					top: 6px;
					left: 4px;
					width: 4px;
					height: 6px;
					transform: rotate(40deg);
					border-bottom: 2px solid #999;
					border-right: 2px solid #999;
					@include xs {
						top: 50%;
						left: 15px;
						width: 6px;
						height: 8px;
						margin-top: -2px;
						transform: rotate(40deg) translate(0, -50%);
					}
				}
			}							
		}
	}

	// radioボタン
	.btn_radio {
		span.wpcf7-list-item.first {
			margin-left: 0;
		}

		input[type=radio] {
			display: none;

			& + span:before {
				content: "\f360";
				font-family: $icon;
				margin-right: 7px;
				color: #aaa;
				font-size: 18px;
				position: relative;
				top: 2px;
			}

			&:checked + span:before{
				content: "\f361";
				font-family: $icon;
				color: #aaa;
			}
		}

		label {
			span {
				padding-left: calc( 1em + 10px );
				text-indent: calc( -1em - 10px);
			}
		}

		&.inquiry-select {
			span {
				display: block;
				margin: 0;
				line-height: 1.2;
	
				label {
					display: flex;
					align-items: center;
					@include xs {
						padding: 10px;
						background: #f5f5f5;
						margin: 0 0 5px;
						border-radius: 5px;
					}
	
					span {
						display: inline;
					}
				}
	
			}
		}
	}


	// file添付
	input[type=file] {
		@include fz(11);
	}

	label.select_type01 {
		position: relative;
		padding: 0;
		display: inline;
		overflow: hidden;
		border-radius: 0;
		z-index: 0;
		margin-right: 5px;
		@include fz(15);
		@include xs {
			width: 100%;
		}
								
		&::after {
			content: "";
			position: absolute;
			display: block;
			width: 0;
			height: 0;
			top: 50%;
			right: 10px;
			margin-top: -.2em;
			border-width: .45em .3em;
			border-style: solid;
			border-color: #000 transparent transparent;
			z-index: -1;
		}

		select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			padding: 10px 40px 10px 15px;
			border: 1px solid #ccc;
			border-radius: 0;
			outline: none;
			background: transparent;

			@include xs {
				width: 100%;
				padding: 15px 40px 15px 15px;
			}

		}
	}

	label.select_year {
		@include xs {
			width: 25%;
			display: inline;

			select {
				width: initial;
			}
		}
	}
	label.select_month,
	label.select_day {
		@include xs {
			width: 20%;
			display: inline;

			select {
				width: initial;
			}
		}
	}

	.wpcf7-form-control.g-recaptcha.wpcf7-recaptcha > div {
		margin: 0 auto;
	}

	.text_area {
		margin-bottom: 40px;
		&.bd_blue {
			border: 4px solid $blue01;
			padding: 40px 40px 20px;
			@include xs {
				border: 2px solid $blue01;
				padding: 20px;
			}
			p {
				font-weight: 600;
				margin-bottom: 10px;
			}
		}

		ul.caution {
			li {
				@include fz(12);
				font-weight: 500;
				@include xs {
					@include fz(10);
				}
			}
		}
	}

	table.contact_form__table {
		width: 100%;
		@include xs {
			margin-bottom: 0px;
		}
		tbody {
			tr {
				th {
					vertical-align: top;
					width: 200px;

					@include xs {
						width: 100%;
						border: 0;
					}
				}
				td {
					@include xs {
						display: block;
						padding: 20px;
						vertical-align: middle;
					}

				}
			}
		}
	}

	span.must {
		border: 2px solid #de5151;
		@include fz(10);
		color: #de5151;
		font-weight: 700;
		padding: 0px 5px;
		line-height: 1;
		margin-left: 10px;
	}

	.submit_btn {
		margin-top: 20px;
		text-align: center;
		
		#button_mfp_goconfirm {
			-webkit-appearance: none;
			appearance: none;
			-moz-appearance: none;
			background: #1466b1;
			padding: 15px;
			color: #ffffff;
			@include fz(18);
			border: 0;
			min-width: 320px;
			display: inline-block;
			position: relative;
			letter-spacing: 0.05em;

			@include xs {
				@include fz(16);
				min-width: auto;
				width: 100%;
			}

			&::before {
				content: '';
				position: absolute;
				text-align: center;
				margin-top: -7px;
				right: 20px;
				top: 50%;
				width: 14px;
				height: 14px;
				border-top: 1px solid #fff;
				border-right: 1px solid #fff;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}
	}

	//alert
	.screen-reader-response,
	.wpcf7-response-output {
		display: none;

		&[role=alert] {
			display: block;
			margin: 30px 0;
			color: $red;
			padding: 15px;
			border: 2px solid $red;

		}
		ul {
			margin-bottom: 0;
			@include fz(13);
			li {
				&::before {
					color: $red;
				}
			}
		}
	}


	span.wpcf7-not-valid-tip {
		display: block;
		color: $red;
		@include fz(13);
		margin-top: 3px;
	}

	.wpcf7-mail-sent-ok {
		display: none !important;
	}
}


/* thanks ------------------------------- */
.mail_thanks {
	// .page_ttl {
	// 	margin-bottom: 0;
	// }

	h2 {
		border: 0;
		padding: 0;
	}

	main {
		// background: url(../img/contact/bg_thanks.jpg) no-repeat bottom center #f9f7f4;
		// background-size: auto 315px;
		// margin-bottom: 0;
		// padding-top: 30px;

		@include xs {
			background-size: 100% auto;
		}
		.mail_thanks_box {
			padding: 0 0 100px;
			text-align: center;
			
			@include xs {
				text-align: left;
			}

			.title_box h2::after {
				left: 50%;
				margin-left: -50px;

				@include xs {
					left: 0;
					margin-left: 0;
				}
			}
		}

		.text_area {
			margin-bottom: 40px;
		}

		.btn_area {
			text-align: center;
			display: block;


			.btn_column {
				display: inline-block;
				max-width: 320px;
				a {
					display: block;
					border: 1px solid #000;
					padding: 15px 50px;
					color: #000000;
					position: relative;
					@include fz(13);

					&::after {
						content: '';
						position: absolute;
						text-align: center;
						margin-top: -5px;
						left: 20px;
						top: 50%;
						width: 10px;
						height: 10px;
						border-top: 1px solid #000;
						border-right: 1px solid #000;
						-webkit-transform: rotate(225deg);
						transform: rotate(225deg);
					}
				}
			}
		}
	}
}

.consulting02 {
	.topicpath {
		li.post {
			display: none;

			&:last-child {
				display: inline;
			}
		}
	}
}