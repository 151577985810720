.single-column {
	main {
		article {

			img {
				// max-width: 600px;
				margin: 0 auto;
				display: inherit;
				@include xs {
					max-width: 100%;
				}
			}


			.hidden-xs,
			.pc_only {
				display: block !important;
				@include xs {
					display: none !important;
				}
			}

			.visible-xs,
			.sp_only {
				display: none !important;
				@include xs {
					display: block !important;
				}
			}

		}
	}
}