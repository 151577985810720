@charset "UTF-8";

/* font */
@import "_font/_notosansjapanese";
@import "_font/_montserrat";
@import "_font/_ionicons";
@import "_font/_fontawesome";

@import "_font/_yakuhanjp_s-narrow.scss";
@import "_font/_yakuhanjp_s-noto.scss";
@import "_font/_yakuhanjp_s.scss";
@import "_font/_yakuhanjp-narrow.scss";
@import "_font/_yakuhanjp-noto.scss";
@import "_font/_yakuhanjp.scss";
@import "_font/_yakuhanmp_s-noto.scss";
@import "_font/_yakuhanmp_s.scss";
@import "_font/_yakuhanmp-noto.scss";
@import "_font/_yakuhanmp.scss";
@import "_font/_yakuhanrp_s.scss";
@import "_font/_yakuhanrp.scss";

// mixin ------------- */
@mixin ff_montserrat {
	font-family: $montserrat, sans-serif;
}

@mixin yumincho{
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}



// icon --------------- */
@mixin arrow_r {
  content: "\f3d1";
  font-family: $icon;
}

// icon --------------- */
@mixin fa {
  font-family: $icon_fa;
  font-weight: 900;
}
