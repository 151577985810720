.partner {
	&__block {
		display: flex;
		align-items: center;
		justify-content: left;
		gap: 0 10px;

		@include xs {
			display: block;
			text-align: center;
			& > div {
				margin: 0 0 0.6em;
				&:last-child {
					margin: 0;
				}
			}
		}

		p {
			display: none;
		}
	}
}