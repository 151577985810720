//----------------------------------------------------
/*! Type: YakuHanMPs - Based on Noto Serif CJK JP */
//----------------------------------------------------

$yakuhanmps-font-family: "YakuHanMPs" !default;
$yakuhanmps-font-file: "YakuHanMPs" !default;
$yakuhanmps-font-dir: "../font" !default;
$yakuhanmps-swap-use: true !default;
$yakuhanmps-eot-use: true !default;
$yakuhanmps-cdn-use: false !default;
$yakuhanmps-cdn-version: "3.3.1" !default;
$yakuhanmps-unicode-range-use: true !default;
$yakuhanmps-unicode: U+300a-3011, U+3014-3015, U+ff08-ff09, U+ff3b, U+ff3d,
  U+ff5b, U+ff5d !default;
$yakuhanmps-weight: (
  100: "ExtraLight",
  200: "Light",
  300: "Regular",
  400: "Medium",
  500: "SemiBold",
  700: "Bold",
  900: "Black"
) !default;

@each $key, $value in $yakuhanmps-weight {
  @font-face {
    $family: $yakuhanmps-font-family;
    $file: $yakuhanmps-font-file;
    $dir: $yakuhanmps-font-dir;
    $swap: $yakuhanmps-swap-use;
    $eot: $yakuhanmps-eot-use;
    $cdn: $yakuhanmps-cdn-use;
    $version: $yakuhanmps-cdn-version;
    $jsdelivr: "https://cdn.jsdelivr.net/npm/yakuhanjp";
    $unicode-range: $yakuhanmps-unicode-range-use;
    $unicode: $yakuhanmps-unicode;

    font-family: $family;
    font-style: normal;
    font-weight: $key;

    @if $swap == true {
      font-display: swap;
    }

    @if $eot == true and $cdn == true {
      src: url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.eot");
    }

    @if $eot == true and $cdn == false {
      src: url("#{$dir}/#{$file}/#{$file}-#{$value}.eot");
    }

    @if $cdn == true {
      src: url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.woff2")
          format("woff2"),
        url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.woff")
          format("woff");
    } @else {
      src: url("#{$dir}/#{$file}/#{$file}-#{$value}.woff2") format("woff2"),
        url("#{$dir}/#{$file}/#{$file}-#{$value}.woff") format("woff");
    }

    @if $unicode-range == true {
      unicode-range: $unicode;
    }
  }
}
