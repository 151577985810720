.cor-stance {
	padding: 6em 0;

	&-title__wrapper {
		margin: 0 auto 3em;
		max-width: calc(1360px + 10%);
		padding: 0 5%;

		p {
			flex: 1;
			@include ffz(15, 14);
			letter-spacing: 0.08em;
			margin: 0 0 1em;
		}
	}

	.title_box {

		h2 {
			@include ffz(24, 20);
			padding: 0;
			margin: 0 0 1em;


			&::after {
				display: none;
				content: "";
			}

			span.ttl_en {
				// color: $blue01;
				@include ffz(11, 10);
				letter-spacing: .1em;
			}
		}
	}

	&__container {
		padding: 0 5%;
		margin: 0 calc(50% - 50vw);
		width: 100vw;
		background: $sub-color;

	}


	&__stack {
		&__wrapper {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 3em 0;
			max-width: calc(1360px + 10%);
			margin: 0 auto;

			// @include sm-lte {
			// 	flex-direction: column;
			// }
		}

		width: 31%;
		border: solid #ccc;
		border-width: 0;

		@include md-lte {
			width: 100%;
			margin: 0 0 3em;
		}



		p {
			margin: 0 0 1em;
			letter-spacing: .08em;
		}
	}

	&-media {
		margin: 0 0 0 1em;
		// width: 33%;

		@include md-gt {
			order: 1;
			width: 100%;
			margin: 0 0 2em;
		}

		@include md-lte {
			width: 40%;
			margin: 0 0 0 3rem;
		}

		img {
			width: 100%;
			height: auto;
		}
	}
	
	&__link__block {
		color: $base-text-color;
		overflow: hidden;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		background: $sub-color;
		
		&:not(:last-child) {
			padding: 0 0 3em;
		}

		&:hover {
			color: $base-text-color;
		}

	}

	&-title__h3 {
		@include ffz(18, 16);
		letter-spacing: .05em;
		margin: 0 0 .8em;
	}

	&-information {
		flex: 1;

		@include md-gt {
			order: 2;
		}
		p {
			@include ffz(14, 12);
		}
	}

	&__link {
		&::before {
			@include ionicon;
			content: "\f12b";
			color: $blue04;
			margin: 0 .3em 0 0 ;
		}
	}


}




.cor-member {
	padding: 6em 0;
	position: relative;
	margin: 0 calc(50% - 50vw);
	width: 100vw;

	&__inner {
		background: #fff;
		z-index: -3;
		position: relative;
	}


	&-title__wrapper {
		margin: 0 auto 3em;
		max-width: calc(1360px + 10%);
		padding: 0 5%;

		p {
			flex: 1;
			@include ffz(15, 14);
			letter-spacing: 0.08em;
			margin: 0 0 1em;
		}
	}


	.title_box {
		width: 30%;

		h2 {
			@include ffz(24, 20);
			padding: 0;
			margin: 0 0 1em;


			&::after {
				display: none;
				content: "";
			}

			span.ttl_en {
				// color: $blue01;
				@include ffz(11, 10);
				letter-spacing: .1em;
			}
		}
	}


	.member_block {
		display: flex;
		flex-wrap:wrap;
		width: 100%;
		// max-width: 1100px;
		margin: 2em auto 0;

		@include sm {
			min-width: 100%;
		}

		@include xs {
			display: block;
			min-width: 100%;
			flex-wrap: nowrap;
		}

		.member_column {
			display: inline-block;
			// width: 50%;
			width: 33.3%;
			vertical-align: middle;

			@include sm-lte {
				width: 50%;
			}

			@include xs {
				width: 100%;
				display: block;
			}

			figure {
				display: block;
			}

			&__position {
				text-align: center;
				margin: 0 auto;
				padding: 1em 0;
				position: relative;
				@include ffz(15, 13);
				color: darken($sub-color, 30%);
				// margin-bottom: calc(-1.8em + 1px);

				// &::before,
				&::after  {
					content: "";
					display: block;
					height: 2px;
					width: 100%;
					background: darken($sub-color, 10%);
					position: absolute;
					top: 50%;
					z-index: -1;
				}

				// &::before {
				// 	left: 0;
				// }

				// &::after {
				// 	right: 0;
				// }

				span {
						background: darken($sub-color, 30%);
						color: #fff;
						padding: .3em 1em;
						border-radius: 1.5em;
				}

			}

			
		}
		.member_ph {
			width: 100%;
			background: #e8eded;
			text-align: center;	
			img {
				width: 100%;
				max-width: 700px;
				height: auto;
			}

			@include xs {
				max-width: auto;
			}
		}

		.member_profile {
			padding: 40px 40px 0;
			max-width: 500px;
			margin: 0 auto;
			@include xs {
				padding: 20px;
				max-width: auto;
			}

			h3 {
				text-align: center;
				@include fz(18);
				font-weight: 500;
				margin-bottom: 5px;
				background: none;
				padding: 0;
				@include xs {
					margin-bottom: 5px;
				}
			}
			.position {
				text-align: center;
				margin-bottom: 15px;
				color: #999;
				@include ff_montserrat;
				@include ffz(12, 11);
			}

			.introduction {
				@include fz(14);
				text-align: justify;
			}
		}
	}
}