@font-face {
     font-family: 'Font Awesome 5 Free';
	 font-style: normal;
	 font-weight: 900;
	 src: url(../font/fa/fa-solid-900.woff2) format("woff2"),
		 url(../font/ionicons/fa-solid-900.woff) format("woff");
     font-display: swap;
}

// @font-face {
//      font-family: 'Font Awesome 5 Free';
// 	 font-style: normal;
// 	 font-weight: 400;
// 	 src: url(../font/fa/fa-regular-400.woff2) format("woff2"),
// 		 url(../font/ionicons/fa-regular-400.woff) format("woff");
//      font-display: swap;
// }


@font-face {
     font-family: 'Font Awesome 5 Brand';
	 font-style: normal;
	 src: url(../font/fa/fa-brands-400.woff2) format("woff2"),
		 url(../font/ionicons//fa-brands-400.woff) format("woff");
     font-display: swap;
}