@import "_variables";

.recruit {
	main {
		.column_block.column_3 {
			
			.column_box {
				text-align: center;
				@include xs {
					text-align: left;
				}
			
			}

			figure {
				@include xs {
					display: flex;
					align-items: center;
					img {
						margin-right: 20px;
						margin-bottom: 0;
						width: 30%;
						max-width: 110px;
					}
				}
				figcaption {
					margin-bottom: 0;
					line-height: 1.3;
					@include xs {
						@include fz(15);
					}
				}
			}
		}
	}
}