/* main ---------------------- */

.main_wrapper {
	display: flex;
	justify-content: center;
	flex-direction: column;
	// width: 1000px;
	margin: 0 auto;
	max-width: 1240px;

	@include sm-gt {

		.post-type-column & {
			flex-direction: row;

		}
	}

	@include xs {
		display: block;
		width: 100%;
	}

	main {
		flex: 1;
		// max-width: 800px;
	}

}
