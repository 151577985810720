.btn-cta__consultation {
	text-align: center;

	a {
		background: linear-gradient(91deg, rgba(71, 131, 192, 1) 10%, rgba(44, 103, 172, 1) 50%, rgba(27, 67, 117, 1) 90%);
		// font-size: 1.8rem;
		@include fz(18);
		letter-spacing: .05em;
		font-weight: 500;
		padding: 1.5em 5em;
		color: #fff;
		position: relative;
		display: inline-block;
		border-radius: 10px;

		&:after {
			content: "\f3d1";
			font-family: $icon;
			color: #fff;
			position: absolute;
			top: 50%;
			right: 20px;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
		}

		@include xs {
			@include fz(16);
			padding: 1.3em 3em 1.3em 2em;
			line-height: 1.4;
		}

		span {
			color: $main-color;
			background: rgba($color: #fff, $alpha: 0.97);
			padding: .5em 1em;
			border-radius: 1000px;
			font-size: 1.2em;
			margin: 0 .6em 0 0;
			font-weight: bold;

			@include sm-lte {
				display: block;
				margin: 0 0 .6em;
			}
		}

	}

}