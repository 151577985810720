/* footer ---------------------- */
footer {
	padding: 20px 0 50px;
	border: solid #ccc;
	border-width: 1px 0 0 0;

	@include sm-lte {
		padding: 30px 0;
	}
}

.footer {
	&-body {
		padding: 40px 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row-reverse;
		gap: 30px 70px;
		@include fz(13);
		letter-spacing: .1em;
		position: relative;

		@include sm-lte {
			flex-direction: column;
			align-items: stretch;
			width: 100%;
			padding: 0 5%;
			gap: 20px;
		}
	}


	&-company {
		&__wrapper {
			display: flex;
			gap: 70px;
			position: relative;

			@include sm-lte {
				gap: 20px;
				flex-direction: column;
				@include fz(11);
			}
		}

		&-logo {
			margin: 0 0 20px;

			@include sm-lte {
				img {
					height: 25px;
					width: auto;
				}
			}
		}
	}

	&-partner {
		p {
			margin: 0 0 15px;

			@include xs {
				@include fz(10);
			}
		}

		&-body {
			display: flex;
			align-items: center;
			gap: 10px;
		}

		&__google {
			img {
				width: 80px;
			}
		}
	}


	&-nav {
		position: relative;
		padding: 0 0 0 70px;

		@include sm-lte {
			padding: 0;
			width: 100%;
		}

		&::after {
			content: "";
			display: block;
			width: 1px;
			height: calc(100% + 40px);
			background: #ccc;
			position: absolute;
			top: -20px;
			left: 0;

			@include sm-lte {
				display: none;
			}
		}

		&-inquiry {
			display: flex;
			border: 1px solid $main-color;
			margin: 0 0 30px;

			@include sm-lte {
				margin: 0 0 20px;

				&__item {
					width: calc(100% / 3);
					text-align: center;
				}
			}

			&__link {
				display: inline-block;
				padding: 10px 30px;
				color: $main-color;

				@include sm-lte {
					width: 100%;
					display: block;
					@include fz(11);
				}


				&.inquiry {
					position: relative;
					&::after {
						content: "";
						display: block;
						width: 1px;
						height: 50%;
						background: $main-color;
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
					}
				}

				&.contact {
					background: $main-color;
					color: #fff;
				}
			}
		}

		&-sitemap {
			column-count: 2;
			// width: fit-content;
			// column-width: 50%;
			
			@include sm-lte {
				@include fz(11);
				column-count: 1;
				display: flex;
				flex-wrap: wrap;
			}

			&__item {
				margin: 0 0 15px;

				@include sm-lte {
					flex-basis: calc(100% / 3);
				}

				// @include xs {
				// 	flex-basis: 50%;
				// }
			}
			
			&__link {
				color: $base-text-color;
			}
		}
	}

	&-foot {
		// position: absolute;
		// left: 0;
		// bottom: 0;
		@include fz(11);
		margin: 30px 0 0;

		@include sm-lte {
			@include fz(10);
		}
	}
}



// footer {
// 	// background: #00427A;
// 	color: #000;
// 	font-weight: 300;
// 	border-top: 1px solid #ccc;
// 	padding: 50px 30px 30px;
// 	background: #ffffff;
// 	position: relative;
// 	z-index: 0;
// 	@include xs {
// 		padding: 50px 20px 30px;
// 	}

// 	.footer {
// 		&_wrapper {
// 			display: flex;
// 			justify-content: center;
// 			margin-bottom: 2em;
// 			// flex-wrap: wrap;
// 			// align-items: center;

// 			@include xs {
// 				display: block;
// 				margin: 0;
// 				text-align: center;
// 			}
// 		}

// 		&__block {
// 			@include xs {
// 				margin-bottom: 2em;
// 			}

// 			&.left {
// 				margin-right: 5em;
// 				padding-right: 5em;
// 				border-right: 1px solid #ccc;
// 				display: flex;
// 				align-items: center;

// 				@include xs {
// 					margin: 0 0 2em;
// 					padding: 0;
// 					border: 0;
// 					display: block;
// 				}

// 				.footer_logo {
// 					margin-bottom: 1em;
// 				}
			
// 				address {
// 					@include fz(13);
// 					font-weight: 400;

// 					span {
// 						@include fz(10);
// 					}
// 				}
// 			}

// 			&.right {
// 				@include fz(12);
// 				p {
// 					margin-bottom: .5em;
// 				}
// 			}
// 		}

// 		&_partner {
// 			display: flex;
// 			align-items: center;
// 			gap: 0 10px;
// 			@include xs {
// 				display: block;
// 				text-align: center;
// 				width: 100%;
// 			}

// 			&__yahoo {
// 				height: 82px;
// 			}
// 		}
// 	}



// 	.glasses_copyright small {
// 		color: #000000;
// 		display: block;
// 		text-align: center;
// 		@include ff_montserrat;
// 		@include fz(11);
// 		font-weight: 400;
// 	}
// }

// .btn_pagetop {
// 	position: fixed;
// 	bottom: 0;
// 	right: 0;
// 	z-index: 10;
// 	a {
// 		 display: block;
// 	}
// }


// .footer {
// 	&_partner {
// 		display: flex;
// 		align-items: center;
// 		@include xs {
// 			display: block;
// 			text-align: center;
// 			width: 100%;
// 		}

// 		&__yahoo {
// 			height: 82px;
// 		}
// 	}
// }