@charset "UTF-8";

.home {

	.main_wrapper {
		position: relative;
		display: block;
		width: 100%;
		max-width: initial;
	}

	.box_w {
		max-width: 1200px;
	}

	.marker_blue {
		background: $blue03;
		color: #fff;
		padding: 3px 8px;
		margin: 0 .2em;
		display: inline-block;
	}

	.c__blue03 {
		color: $blue03;
	}

	.bg_line {
		// background: url() no-repeat center top;
		// position: absolute;
		// top: 350px;
		// top: 350px;
		// z-index: -100;
		width: 100%;
		height: 80%;
		position: fixed;
		top: 120vh;
		z-index: -300;

		@include xs {
			top: 80%;
		}

		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			background: $blue03;
			position: absolute;
			top: 0;
			left: 0;
			// transform: skewY(-27.3deg);
			transform: skewY(-20deg);
			transform-origin: bottom left;
			z-index: -50;
		}

		img {
			width: 100%;
		}
	}

	@include xs {
		background-size: 130% auto;
		background-position: 0 0%;
	}

	p {
		// margin: 0;
		// padding: 0;
		border: 0;
		font: inherit;
		font-size: 100%;
		vertical-align: baseline;
		@include ffz(16, 14);
		letter-spacing: .05em;
	}

	h2 {
		border: 0;
		padding: 0 !important;
	}

	.c__blue03 {
		color: $blue03;
	}

	&-hero {
		margin: 100px 0;
		position: relative;
		overflow-x: hidden;

		@include sm-lte {
			margin: 10% 6%;
		}

		&::after {
			content: "";
			display: block;
			width: 985px;
			height: 500px;
			background: url(../img/index/img-hero-bg.svg) no-repeat center / contain;
			position: absolute;
			top: 0;
			right: -18% ;
			z-index: -100;

			@include sm {
				width: 550px;
			}

			@include xs {
				top: 30px;
				width: 110%;
				right: -25%;
			}
		}

		&-fv {
			display: flex;
			justify-content: center;

			@include sm {
				flex-direction: column;
			}
		}

		&-copy {
			@include ffz(42, 27);
			font-weight: 700;
			margin: 0 0 1em;

			@include sm {
				@include fz(34);
			}
		}

		&-description {
			margin: 0 0 50px;

			@include xs {
				margin: 0 0 10px;
			}

			&-title {
				@include ffz(26, 18);
				font-weight: 600;
				letter-spacing: .05em;
				margin: 0 0 20px;

				@include xs {
					margin: 0 0 10px;
				}
			}


			strong {
				font-weight: bold;
			}

			@include xs {
				p {
					@include fz(13);
				}
			}
		}
	}

	&-cta {
		&__inner {
			display: flex;
			gap: 60px;
			align-items: flex-end;

			@include sm {
				gap: initial;
				justify-content: space-between;
			}

			@include xs {
				flex-direction: column;
				align-items: flex-start;
				gap: 20px 0;
			}
		}
		&-copy {
			p {
				@include ffz(26, 17);
				font-weight: bold;
				display: inline-block;
				letter-spacing: .05em;

				@include sm {
					letter-spacing: .01em;
				}

				@include xs {
					line-height: 1.8;
				}
				
				span {
					border: solid $base-text-color;
					border-width: 0 0 1px;
					display: inline;
					padding: 0 0 5px;
				}

				b {
					@include ffz(50, 30);
				}
			}
		}

		&-button {
			@include sm {
				width: 45%;
			}

			@include xs {
				width: 100%;
			}
		}

		&__link {
			display: inline-block;
			padding: 24px 60px 24px 40px;
			background: linear-gradient(90deg, #4783C0 9.75%, #2C67AC 48.63%, #1B4375 87.5%);
			color: #fff;
			font-weight: 700;
			@include ffz(16, 13);
			text-align: right;
			position: relative;
			letter-spacing: .05em;

			@include sm-lte {
				width: 100%;
				padding: 20px 50px 20px 20px;
			}
			@include xs {
				letter-spacing: .08em;
				padding: 20px 60px 20px 40px;
				text-align: left;
			}

			span {
				@include ffz(24, 18);
				display: block;

				@include sm {
					@include fz(18);
				}
			}

			&::after {
				content: "";
				display: block;
				width: 15px;
				height: 15px;
				background: url(../img/common/icon-arrow-circle.svg) no-repeat center / contain;
				position: absolute;
				top: 50%;
				right: 20px;
				transform: translateY(-50%);
			}
		}
	}

	&-hero-feature {
		&-list {
			max-width: 1200px;
			margin: 90px auto 110px;
			display: flex;
			justify-content: space-between;
			gap: 16px;

			@include sm-lte {
				flex-wrap: wrap;
				gap: 10px;
				margin: 60px auto;
			}

			&__item {
				width: calc(100% / 4);
				border: 2px solid #ccc;
				border-radius: 10px;
				padding: 20px;
				text-align: center;

				@include sm-lte {
					width: calc(50% - 5px);
					padding: 20px 15px;
					border-width: 1px;
				}

				p {
					font-weight: 600;
					@include ffz(18, 13);
				}
			}

		}

		&-num {
			@include ffz(28, 22);
			font-weight: bold;
			border: solid $blue01;
			border-width: 0 0 1px;
			color: $blue01;
			display: inline-block;
			margin: 0 0 .5em;
			letter-spacing: .05em;

		}
	}

	.home-worries {
		background: #f5f5f5;
		position: relative;
		z-index: 0;
		padding: 0 0 10px;

		&::before {
			content: "";
			// width: 100%;
			// height: 784px;
			// background: #fff;
			position: absolute;
			top: 0;
			left: 0;
			border-bottom: 784px solid transparent;
			border-left: 100vw solid #fff;
			z-index: -10;

			@include xs {
				border-bottom-width: 240px;
			}
		}


		&-title__01 {
			text-align: center;
			@include ffz(38, 22);
			line-height: 1.4;
			letter-spacing: .04em;
			font-weight: bold;
			margin: 0 0 70px;

			@include xs {
				margin: 0 0 20px;
			}
		}

		&-example {
			display: flex;
			gap: 30px;
			justify-content: center;
			margin: 0 0 180px;

			@include sm-lte {
				width: 90%;
				margin: 0 auto 50px;
			}

			@include xs {
				flex-direction: column;
				width: 80%;
			}

			&-image {
				@include xs {
					text-align: center;
					img {
						width: 45%;
					}
				}
			}

			&-list {
				&__item {
					@include ffz(21, 15);
					font-weight: bold;
					margin: 0 0 15px;
					letter-spacing: .04em;
					position: relative;
					padding: 0 0 0 20px;

					@include xs {
						margin: 0 0 10px;
						letter-spacing: 0.02em;
						margin: 0 0 10px;
					}

					&::before {
						content: "";
						display: inline-block;
						width: 10px;
						height: 10px;
						border-radius: 100px;
						background: #D9D9D9;
						margin: 0 10px 0 0;
						position: absolute;
						left: 0;
						top: 1em;
						transform: translateY(-50%);

						@include xs {
							width: 8px;
							height: 8px;
						}
					}
				}

				&::after {
					content: "etc…";
					font-weight: bold;
					@include ffz(27, 16);
					color: $blue03;
					margin: 20px 0 0;
					letter-spacing: .1em;

					@include xs {
						text-align: right;
						display: block;
					}
				}
			}

		}
		
		&-point {
			@include ffz(24, 18);
			text-align: center;

			&-lead {
				@include ffz(24, 16);
				font-weight: 500;
				margin: 0 0 40px;

				@include xs {
					padding: 0 10%;
					letter-spacing: normal;
				}

				span {
					@include ffz(26, 18);
					font-weight: 600;

					&::before {
						content: "";
						display: inline-block;
						width: 28px;
						height: 28px;
						background: url(../img/common/icon-search.svg) no-repeat center / contain;
						margin: 0 8px 0 0;
						transform: translateY(3px);

						@include xs {
							width: 20px;
							height: 20px;
							margin: 0 3px 0 0;
						}
					}
				}
			}

			&-doubt {
				&-comment {
					max-width: 1000px;
					margin: 0 auto;
					@include ffz(34, 20);
					padding: 30px;
					background: #CFE2F3 url(../img/index/img-warries-02.svg) no-repeat bottom 0 right 60px / contain;
					position: relative;

					@include xs {
						padding: 30px 5% 180px;
						background-size: 45%;
						background-position: center bottom;
					}

					p {
						@include ffz(34, 20);
						font-weight: 700;
					}

					&::after {
						content: "";
						display: block;
						width: 130px;
						height: 80px;
						background: #cfe2f3;
						position: absolute;
						left: 50%;
						top: 100%;
						transform: translateX(-50%);
						z-index: 0;

						@include xs {
							width: 100px;
							height: 50px;
						}

					}

					&::before {
						content: "";
						width: 0;
						height: 0;
						border-style: solid;
						border-right: 172px solid transparent;
						border-left: 172px solid transparent;
						border-top: 104px solid #cfe2f3;
						border-bottom: 0;
						position: absolute;
						left: 50%;
						top: calc(100% + 45px);
						transform: translateX(-50%);
						z-index: 0;

						@include xs {
							border-right: 100px solid transparent;
							border-left: 100px solid transparent;
							border-top: 60px solid #cfe2f3;
						}
					}
				}

				&-arrow {
					position: relative;
					z-index: 10;
					margin: 77px 0 20px;

					@include xs {
						text-align: center;
						margin: 50px 0 0;
						img {
							width: 70%;
						}
					}
				}

				&-answer {
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 30px;
					margin: 90px 0 85px;

					@include xs {
						flex-direction: column;
						margin: 40px 0;
					}

					&-company,
					&-manager {
						@include ffz(40, 24);
						font-weight: 700;
						width: 400px;
						position: relative;

						@include sm {
							width: auto;
						}

						@include xs {
							width: 90%;
							margin: 20px auto;
						}

					}

					&-company {
						&::after {
							content: "";
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);

							--b:25px; /* control the border thickness */
							width: 225px;
							aspect-ratio: 1/cos(30deg);
							clip-path: polygon(50% 0,100% 100%,0 100%,50% 0,50% var(--b),calc(var(--b)*cos(30deg)) calc(100% - var(--b)/2),calc(100% - var(--b)*cos(30deg)) calc(100% - var(--b)/2),50% var(--b));
							background: #f3DAD1;
							z-index: -10;

							@include xs {
								width: 40%;
								--b: 20px;
							}
						}
					}

					&-manager {
						&::after {
							content: "";
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							width: 160px;
							height: 160px;
							border-radius: 1000px;
							border: 15px solid #CFE2F3;
							z-index: -10;

							@include xs {
								width: 110px;
								height: 110px;
								border-width: 12px;
							}
						}
					}

					&-than {
						@include ffz(22, 16);
						font-weight: 700;
						color: #fff;
						background: $blue04;
						border-radius: 100px;
						padding: 5px 25px;

						@include xs {
							padding: 3px 20px;
						}

					}

					&__foot {
						margin: 0 0 50px;
						p {
							@include ffz(22, 14);
							font-weight: 500;

							@include xs {
								width: 90%;
								margin: 0 auto;

							}
						}
					}

					&-important {
						margin: 0 0 40px;

						p {
							@include ffz(40, 26);
							font-weight: 700;
						}
					}

				}

				&__foot {

					p {
						@include ffz(22, 15);
						font-weight: 500;
					}
				}

				&-but {
					background: #fff;
					max-width: 1000px;
					padding: 30px 30px 180px 30px;
					border-radius: 15px;
					position: relative;
					margin: 0 auto 100px;

					@include xs {
						padding-bottom: 150px;
						margin: 0 auto 60px;
					}

					p {
						@include ffz(34, 20);
						font-weight: 700;
					}

					&::after {
						content: "";
						display: block;
						width: 232px;
						height: 232px;
						background: url(../img/index/img-warries-03.svg) no-repeat center / contain ;
						position: absolute;
						bottom: -53px;
						left: 50%;
						transform: translate(-50%, 0);

						@include xs {
							width: 48%;
						}
					}

				}


			}
		}
	}

	.home-about {
		background: $blue03;
		position: relative;
		z-index: 0;

		&::after {
			content: "";
			width: 100vw;
			height: 190px;
			background: #f5f5f5;
			position: absolute;
			top: -9px;
			left: 0;
			z-index: -10;

		}

		&::before {
			content: "";
			position: absolute;
			top: 180px;
			left: 0;
			// border-bottom: 53vw solid transparent;
			border-bottom: 53vw solid transparent;
			border-left: 100vw solid #f5f5f5;
			z-index: -10;

			@include xl {
				border-bottom: 40vw solid transparent;
			}
		}

		&-lead {
			@include ffz(40, 24);
			font-weight: 500;
			text-align: center;
			// margin: 40px 0;
			padding: 20px 0;

			@include sm-lte {
				font-weight: bold;
			}

			&::before,
			&::after {
				content: "";
				display: block;
				width: 1px;
				height: 90px;
				background: $blue03;
				margin: 20px auto;


				@include sm-lte {
					height: 45px;
				}
			}
		}

		&-title {
			display: inline;
			padding: 2px 10px;
			box-decoration-break: clone;
			background: #fff;
			@include ffz(40, 20);
			font-weight: 600;

			@include sm {
				@include fz(30);
			}

			@include xs {
				line-height: 1.9;
				padding: 2px 5px;
			}

			&__wrapper {
				max-width: 1000px;
				margin: 0 auto;

				@include sm-lte {
					width: 100%;
					padding: 0 5%;
				}
			}
		}

		&-feature {
			position: relative;
			margin: 0 0 50px;

			@include xs {
				margin: 0 0 30px;
			}

			&-01 {
				position: absolute;
				right: 0;
				top: 0;
				z-index: -10;
				width: 60%;
				margin: -180px 0 0;

				@include sm {
					margin-top: -120px;
				}

				@include xs {
					margin: -60px 0 0;
					width: 80%;
				}
			}
			
			&-02 {
				width: 37%;
				margin: 40px 0 0;

				@include xs {
					width: 45%;
					padding-top: 80px;
				}
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		&-company-info {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 40px;
			max-width: 1000px;
			margin: 0 auto 60px;

			@include sm-lte {
				width: 90%;
				margin: 0 auto 40px;
			}
			@include xs {
				flex-direction: column;
				gap: 20px;
			}

			&-logo {
				@include xs {
					width: 30%;
				}
			}

			&__body {
				p {
					color: #fff;
					@include ffz(18, 13);
					font-weight: 500;
				}
			}
		}

		&-company-evaluation {
			max-width: 1000px;
			margin: 0 auto;

			@include sm-lte {
				width: 100%;
			}

			&__body {
				background: #fff;
				padding: 80px 70px;
				position: relative;
				z-index: 0;

				@include sm-lte {
					padding: 40px 5%;
				}
			}

			&-title {
				text-align: center;
				@include ffz(30, 16);
				margin: 0 0 80px;
				font-weight: 600;

				@include sm-lte {
					margin: 0 0 50px;
				}

				u {
					text-decoration: none;
					border-bottom: 1px solid $base-text-color;
				}

				.fz__l {
					@include ffz(40, 24);
					letter-spacing: .04em;

					@include xs {
						margin: 10px 0 0;
						display: inline-block;
					}
				}
			}

			&-list {
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
				justify-content: space-between;
				gap: 50px;
				counter-reset: evaluation;

				@include sm {
					gap: 50px 4%;
				}

				@include xs {
					gap: 30px 2%;
				}

				&__item {
					flex-basis: 405px;
					padding: 0 0 0 35px;
					@include ffz(26, 16);
					font-weight: 600;
					position: relative;
					z-index: 5;
					counter-increment: evaluation;
					letter-spacing: .04em;

					@include sm {
						flex-basis: 48%;
					}

					@include xs {
						flex-basis: 100%;
					}

					&::before {
						content: counter(evaluation, decimal-leading-zero);
						color: #CFE2F3;
						@include ffz(50, 35);
						position: absolute;
						top: -.8em;
						left: 0;
						z-index: -1;
					}
				}
			}
		}
	}

	.home-conversion {
		padding: 280px 0 120px;

		&.bg__image {
			background: url(../img/index/bg-cta.jpg) no-repeat center / cover;
			margin-top: -150px;

			@include sm-lte {
				margin: 0;
			}

			h2.home-conversion-title,
			h2.home-conversion-title + p {
				color: #fff;
			}
			
			.home-conversion-button__wrapper {
				border: 0;
			}

		}

		@include sm {
			padding: 60px 3%;
			margin-top: 0;
		}

		@include xs {
			padding: 60px 5%;
			margin-top: 0;
		}

		&-title {
			// color: #fff;
			text-align: center;
			@include ffz(40, 20);
			font-weight: 600;
			margin: 0 0 50px;
			letter-spacing: .04em;

			@include xs {
				margin: 0 0 30px;
			}

			span {
				display: block;
				@include ffz(30, 17);
				margin: 0 0 50px;

				@include sm-lte {
					margin: 0 0 30px;
				}
			}

			& + p {
				text-align: center;
				// color: #fff;
				@include ffz(16, 14);
				font-weight: 600;
				margin: 0 0 50px;
				line-height: 1.8;
				letter-spacing: .06em;

				@include xs {
					font-weight: 500;
				}
			}
		}

		&-button {
			&__wrapper {
				background: #fff;
				border: 1px solid #ccc;
				padding: 45px;
				border-radius: 15px;
				max-width: 1200px;
				margin: 0 auto;
				display: flex;
				justify-content: center;

				@include sm {
					padding: 30px 4%;
					justify-content: space-between;
				}

				@include xs {
					padding: 20px 5% 30px;
				}

				p {
					@include xs {
						@include fz(16);
						letter-spacing: .01em;
					}
				}
			}

		}

		.home-cta {
			@include sm {
				gap: initial;
			}

			&__inner {
				@include sm {
					flex-wrap: wrap;
					align-items: center;
				}
			}
		}

		.home-cta__link {
			@include xs {
				padding: 20px 40px 20px 30px;
				
				span {
					@include fz(16);
				}

				&::after {
					right: 15px;
				}
			}
		}
	}

	/* performance ----------------------------- */
	.home-performance {
		position: relative;
		z-index: 0;
		background: #f5f5f5;
		// background: $sub-color;

		// &::after {
		// 	content: "";
		// 	display: block;
		// 	width: 100%;
		// 	height: 100%;
		// 	background: rgba($color: #f5f5f5, $alpha: .96);
		// 	position: absolute;
		// 	top: -5%;
		// 	left: 0;
		// 	transform: skewY(8deg);
		// 	// transform: skewY(27.3deg);
		// 	transform-origin: bottom left;
		// 	z-index: -10;

		// 	@include xs {
		// 		top: -4%;
		// 	}
		// }

		padding: 100px 0;

		@include sm-lte {
			padding: 50px 0;
		}

		&-title {
			text-align: center;
			@include ffz(30, 22);
			margin: 0 0 45px;
			letter-spacing: .01em;

			@include xs {
				margin: 0 0 30px;
			}
		}

		.performance {
			margin: 0 0 100px;
		}


		&-list {
			list-style: none;

			li {
				margin: 0 0 1px;
				padding: 0 0 0 0;
				// border-radius: 10px;
				background: #fff;
				overflow: hidden;
				letter-spacing: .08em;
				position: relative;


				@include xs {
					padding: 0;
					margin: 0 0 30px;
				}

				&::before {
					content: '';
					padding: 0;
					margin: 0;
				}


				&.data_none {
					text-align: center;
					color: #000;
					border: 4px solid #c5c5c5;
					padding: 30px;
					@include xs {
						border: 2px solid #c5c5c5;
						padding: 15px;
						align-items: top;
					}

					.ex_list {
						text-align: center;
					}
				}

				a {
					display: flex;
					align-items: center;
					color: #000;
					// border: 1px solid #c5c5c5;
					// border-width: 0 0 1px 0;
					padding: 0 3em 0 0;
					// background: #fff;

					@include xs {
						flex-direction: column;
						padding: 0;
					}

					&::after {
						@include arrow_r;
						position: absolute;
						right: 2em;
						top: calc(50% - .5em);
						color: #ccc;
						@include ffz(16, 14);

						@include xs {
							display: none;
							content: "";
						}
					}

					&:hover {
						animation: none;
						background: #f5f5f5;
						.ex_list {
							border-color: $blue01;
						}
					}
				}


				.ex_list {
					border: 0;
					text-align: left;
					margin: 0;
					flex: 1;

					@include sm-lte {
						line-height: 1.3;
					}

					@include xs {
						padding: 20px;
						font-weight: 500;
					}
				}
				p {
					@include ffz(22, 13);
					margin-bottom: 10px;
					// font-weight: 400;

					@include xs {
						line-height: 1.5;
					}

					b {
						@include ffz(28, 20);
						font-weight: 400;
						@include xs {
							font-weight: 600;
						}
					}
				}

				span {
					margin: .5em 0 0;

					@include xs {
						@include fz(11);
					}

					&.red {
						@include xs {
							@include fz(16);
							font-weight: 600;
						}
					}
				}

			}
		}

		&__thumbnail {
			max-width: 350px;
			width: 30%;
			height: auto;
			margin-right: 30px;

			@include sm {
				width: 35%;
			}

			@include xs {
				width: 100%;
				height: auto;
				margin: 0;

				img {
					width: 100%;
				}
			}
		}


		.performance {
			&__company {
				line-height: 1.2;
				margin: 0.5em 0 0;

				@include xs {
					margin: 1em 0 0;
				}
			}

			&__more {
				padding: 0;
			}
		}

	}

	/* suppliers ----------------------------- */
	.home-suppliers {
		.box_w {
			border: 1px solid #ccc;
			padding: 75px 100px 45px;
			position: relative;
			margin: 0 auto 85px;

			@include sm-lte {
				padding: 45px 20px 20px;
				margin: 0 auto 40px;
			}
		}

		&-title {
			text-align: center;
			@include ffz(30, 18);
			margin: 0 auto 45px;
			padding: 0 40px;
			letter-spacing: .04em;
			display: inline-block;
			background: #f4f4f4;
			position: absolute;
			top: -.7em;
			left: 50%;
			transform: translateX(-50%);

			@include sm-lte {
				padding: 0 20px;
				width: 90%;
			}
		}

		&-list {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin-left: -10px;
			margin-bottom: 0;

			&__item {
				width: calc(25% - 10px);
				margin: 0 0 10px;
				margin-left: 10px;

				@include sm {
					width: calc(33% - 10px);
				}

				@include xs {
					width: calc(50% - 10px);
				}

				img {
					width: 100%;
				}
			}
		}
	}

	/* home-performance-cv ----------------------------- */
	.home-performance-cv {
		&-title {
			@include ffz(30, 20);
			text-align: center;
			line-height: 1.6;
			margin: 0 0 40px;
		}

		&__body {
			background: #fff;
			padding: 60px 40px;
			max-width: 1200px;
			margin: 0 auto;

			@include sm-lte {
				width: 90%;
				padding: 40px 0;
			}
		}

		&-list {
			display: flex;
			flex-wrap: wrap;

			&__item {
				width: calc(100% / 3);
				position: relative;
				padding: 40px 40px;
				letter-spacing: .04em;

				@include xs {
					width: calc(100% / 2);
					padding: 20px;

					&:not(:nth-child(2n+1)) {
						&::before {
							content: "";
							width: 1px;
							height: 80%;
							background: #ccc;
							position: absolute;
							right: 0;
							top: 50%;
							transform: translateY(-50%);
						}
					}
				}

				@include sm-gt {
					&:not(:nth-child(3n+1)) {
						&::before {
							content: "";
							width: 1px;
							height: 80%;
							background: #ccc;
							position: absolute;
							right: 0;
							top: 50%;
							transform: translateY(-50%);
						}
					}
				}

				&::after {
					content: "";
					display: block;
					width: 80%;
					height: 1px;
					background: #ccc;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
				}

				&:first-child {
					flex-basis: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 64px;
					padding-top: 0;
					margin: 0 40px;

					@include sm-lte {
						flex-direction: column;
						flex-wrap: wrap;
						gap: 0;
					}

					&::before {
						display: none;
					}

					&::after {
						width: 100%;
					}

					.home-performance-cv-industry {
						@include ffz(24, 18);
					}

					.home-performance-cv-rate {
						// margin-top: -50px;
						flex-direction: row;

						&__s {
							@include ffz(40, 18);
						}

						&__m {
							@include ffz(56, 30);
						}

						&__l {
							@include ffz(96, 50);
							line-height: 1;
						}
					}
				}

				@include sm-gt {
					&:nth-last-child(-n+3) {
						padding-bottom: 0;
						&::after {
							display: none;
						}
					}
				}

				@include xs {
					&:nth-last-child(-n+2) {
						padding-bottom: 0;
						&::after {
							display: none;
						}
					}
				}

			}
		}

		&-industry {
			@include ffz(20, 14);
			font-weight: bold;
			margin: 0 0 .5em;
		}

		&-rate {
			display: flex;
			align-items: flex-end;
			justify-content: center;
			color: $blue03;
			font-weight: bold;

			@include sm-lte {
				flex-wrap: wrap;
				justify-content: flex-start;
			}

			&__s {
				@include ffz(24, 16);
				
				@include sm-lte {
					display: block;
					width: 100%;
					text-align: left;
				}
			}

			&__m {
				@include ffz(32, 22);
			}

			&__l {
				@include ffz(64, 38);
				line-height: 1;

				@include xs {
					letter-spacing: 0;
				}
			}

			&-cpa {
				@include ffz(20, 15);
				text-align: center;
				color: $blue03;
				letter-spacing: .06em;
				margin: 10px 0 0;

				@include xs {
					text-align: left;
				}
			}
		}
	}


	/* comparison ----------------------------- */
	.home-comparison {
		padding: 90px 0;

		@include sm-lte {
			padding: 45px 5%;
		}

		&__inner {
			max-width: 1000px;
			margin: 0 auto;
		}

		&-title {
			@include ffz(40, 22);
			font-weight: bold;
			margin: 0 0 60px;
			text-align: center;

			@include sm-lte {
				margin: 0 0 40px;
			}
		}

		&-figure {
			text-align: center;
			margin: 0 0 60px;

			@include sm-lte {
				margin: 0 auto 40px;
				width: calc(100% + 5%);
				overflow: scroll;
				position: relative;

				img {
					width: 700px;
					height: auto;
					max-width: initial;
				}

				&::after {
					content: "\f0a6" + " 横にスクロールできます";
					font-family: $icon_fa;
					@include fz(11);
					display: inline-block;
					position: sticky;
					top: calc(100% + 20px);
					left: 50%;
					transform: translateX(-50%);
					margin: 20px 0;
					padding: 5px 20px;
					text-align: left;
					font-weight: bold;
					background: #999;
					border-radius: 5px;
					color: #fff;
				}
			}
		}

		p:not(:last-child) {
			margin: 0 0 1em;
		}

	}

	/* greeting ----------------------------- */
	.home-greeting {
		background: #f5f5f5;
		padding: 335px 0 160px;
		position: relative;
		z-index: 0;
		
		@include sm {
			padding: 200px 0 60px;
		}

		@include xs {
			padding: 50px 0 50px;
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			border-bottom: 240px solid transparent;
			border-left: 100vw solid #fff;
			z-index: 5;

			@include xs {
				border-bottom-width: 160px;
			}
		}

		&__inner {
			max-width: 1000px;
			margin: 0 auto;
			position: relative;
			z-index: 10;

			@include sm-lte {
				padding: 0 5%;
			}
		}

		&-lead {
			p {
				line-height: 2;

				@include xs {
					line-height: 1.7;
				}
			}
		}

		&-profile {
			display: flex;
			margin: 50px 0 0;

			@include sm-lte {
				flex-direction: column;
			}

			@include xs {
				margin: 30px 0 0;
			}
		}

		&-profile-introduction {
			padding: 0 60px;
			border-left: 1px solid #ccc;
			flex: 1;

			@include sm-lte {
				padding: 0 5%;
				margin: 0;
				order: 2;
			}

			&-name {
				@include ffz(20, 18);
				display: flex;
				gap: 20px;
				align-items: center;
				font-weight: bold;
				letter-spacing: .07em;
				margin: 0 0 15px;

				&__en {
					@include ffz(13, 10);
				}
			}

			&-position {
				@include ffz(12, 11);
				color: #999;
				margin: 0 0 15px;
			}

			p {
				line-height: 1.7;

				@include xs {
					@include fz(13);
				}
			}

			&-successcase {
				margin: 30px 0 0;

				dt {
					background: $blue03;
					color: #fff;
					@include fz(12);
					padding: 2px 4px;
					display: inline-block;
					letter-spacing: .04em;
					margin: 0 0 1em;

					@include xs {
						margin: 0 0 0.5em;
					}
				}

				dd {
					@include ffz(14, 13);
					letter-spacing: .04em;
				}
			}
		}

		&-profile-figure {
			width: 330px;
			
			@include sm-lte {
				order: 1;
				width: 50%;
				margin: 0 auto 30px;
			}
		}

		& + .home-conversion {
			position: relative;
			padding: 0px 0 100px;

			@include sm-lte {
				padding: 0 5% 60px;
			}

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				border-bottom: 240px solid transparent;
				border-left: 100vw solid #f5f5f5;
				z-index: 5;

				@include xs {
					border-bottom-width: 160px;
				}
			}

			.home-conversion__inner {
				position: relative;
				z-index: 10;
			}
		}
	}



	.home-column {
		margin: 0 0 100px;

		&-list {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			@include xs {
				flex-direction: column;
			}

			&__item {
				width: 24%;
				color: #000;
				background: $sub-color;
				border-radius: $border-radius;
				overflow: hidden;

				@include sm {
					width: 49%;
					margin: 0 0 20px;
				}

				@include xs {
					width: 100%;
					margin: 0 0 1em;
					&:last-child {
						border: 0;
					}
				}
				
				time {
					@include fz(12);
					color: #999;
					margin: 0 0 .5em;
					letter-spacing: .05em;
					display: inline-block;

					@include xs {
						margin: 0;
					}
				}

				figure {
					height: auto;
					object-fit: contain;

					@include xs {
						display: flex;
						align-items: flex-start;
						padding: 25px;
						img {
							width: 40%;
							height: auto;
							object-fit: contain;
							margin-right: 15px;
						}
					}

					figcaption {
						margin: 0;
						padding: 25px;

						@include xs {
							padding: 0;
						}
					}
				}
			}

			&-title {
				font-weight: bold;
				line-height: 1.4;
				margin: 0 0 .8em;
				@include ffz(16, 14);

				@include xs {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					overflow: hidden;
					margin: 0;
				}
			}

			&-copy {
				@include ffz(12, 11);
				line-height: 1.5;

				@include xs {
					display: none;
				}
			}
		}
	}


}