//----------------------------------------------------
/*! Type: YakuHanRPs - Based on Rounded M+ 1c */
//----------------------------------------------------

$yakuhanrps-font-family: "YakuHanRPs" !default;
$yakuhanrps-font-file: "YakuHanRPs" !default;
$yakuhanrps-font-dir: "../font" !default;
$yakuhanrps-swap-use: true !default;
$yakuhanrps-eot-use: true !default;
$yakuhanrps-cdn-use: false !default;
$yakuhanrps-cdn-version: "3.3.1" !default;
$yakuhanrps-unicode-range-use: true !default;
$yakuhanrps-unicode: U+3008-3011, U+3014-3015, U+ff08-ff09, U+ff3b, U+ff3d,
  U+ff5b, U+ff5d !default;
$yakuhanrps-weight: (
  100: "Thin",
  300: "Light",
  400: "Regular",
  500: "Medium",
  700: "Bold",
  800: "ExtraBold",
  900: "Black"
) !default;

@each $key, $value in $yakuhanrps-weight {
  @font-face {
    $family: $yakuhanrps-font-family;
    $file: $yakuhanrps-font-file;
    $dir: $yakuhanrps-font-dir;
    $swap: $yakuhanrps-swap-use;
    $eot: $yakuhanrps-eot-use;
    $cdn: $yakuhanrps-cdn-use;
    $version: $yakuhanrps-cdn-version;
    $jsdelivr: "https://cdn.jsdelivr.net/npm/yakuhanjp";
    $unicode-range: $yakuhanrps-unicode-range-use;
    $unicode: $yakuhanrps-unicode;

    font-family: $family;
    font-style: normal;
    font-weight: $key;

    @if $swap == true {
      font-display: swap;
    }

    @if $eot == true and $cdn == true {
      src: url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.eot");
    }

    @if $eot == true and $cdn == false {
      src: url("#{$dir}/#{$file}/#{$file}-#{$value}.eot");
    }

    @if $cdn == true {
      src: url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.woff2")
          format("woff2"),
        url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.woff")
          format("woff");
    } @else {
      src: url("#{$dir}/#{$file}/#{$file}-#{$value}.woff2") format("woff2"),
        url("#{$dir}/#{$file}/#{$file}-#{$value}.woff") format("woff");
    }

    @if $unicode-range == true {
      unicode-range: $unicode;
    }
  }
}
