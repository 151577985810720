.company {
	.success_case {

		&_index {
			h1 {
				@include ffz(20, 17);
				margin: 0 0 1rem;
				text-align: left;
				padding: 0 !important;
			}
		}

		&__box {

			a {
				border: 0;
				padding: 3rem;
				border: 0;
				border-radius: $border-radius;
				background: #f5f5f9;

				@include xs {
					padding: 2.5rem;
				}
			}
	
			h2 {
				margin: 0 0 1rem;
				text-align: left;
				padding: 0 !important;
	
				img {
					height: 22px;
					width: auto;

					@include xs {
						height: 20px;
					}
				}
			}
		}

		&__tag {
			display: none;
		}
	}
}