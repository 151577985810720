@charset "UTF-8";

.title_box {
	h2 {
		@include fz(30);
		// @include ff_montserrat;
		font-weight: 500;
		color: #000;
		position: relative;
		margin-bottom: 60px;
		line-height: 1.5;
		letter-spacing: 0.03em;

		@include xs {
			@include fz(18);
			margin-bottom: 30px;
		}

		span {
			display: block;
			@include fz(12);
			font-weight: 500;
			font-family: $noto;
			margin: .5em 0 0;
			@include xs {
				@include fz(10);
				font-weight: 700;
			}

			&.ttl_en {
				@include ff_montserrat;
			}
		}

		&::after {
			content: "";
			width: 100px;
			height: 1px;
			background: #000;
			position: absolute;
			bottom: -30px;
			left: 0;
			@include xs {
				bottom: -15px;
			}
		}

	}

	&.a_right {
		text-align: right;
		h2 {
			display: inline-block;
			text-align: left;
		}
	}
}