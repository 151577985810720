body.fixed {
	overflow: hidden;
}

.modal_contact {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	display: flex;
	align-items: center;
	display: none;

	@include xs {
		display: none !important;
	}

	&.iziModal {
		// background: transparent !important;
		background: rgba(255, 255, 255, 0.9) !important;
		box-shadow: none;
	}

	.iziModal {
		&-wrap {
			width: 100%;
			height: 100vh;
		}
		&-content {
			width: 1150px;
			height: 100vh;
			margin: 0 auto;
			display: flex;
			align-items: center;
			position: relative;
			overflow-y: scroll;
		}
	}


	&__inner {
		margin: 0 auto;
		text-align: center;
		padding: 50px 0;
	}

	&_tel {
		margin: 0 0 2em;
	}

	&__block {
		border: 1px solid #ccc;
		border-top: 0;
		padding: 0 30px 30px;
		position: relative;
		&::before {
			content: "";
			display: block;
			width: 100px;
			height: 1px;
			background: #ccc;
			position: absolute;
			top: 0;
			left: 0;
		}
		&::after {
			content: "";
			display: block;
			width: 100px;
			height: 1px;
			background: #ccc;
			position: absolute;
			top: 0;
			right: 0;
		}
	}

	h2 {
		margin: 0 0 1em;
		@include fz(20);
		color: $blue02;
		position: relative;
		top: -0.6em;
		font-weight: 600;
	}

	h3 {
		@include fz(16);
		margin: 0 0 .5em;
		color: $blue02;
		font-weight: 500;
	}

	.modal_contact_close {
		position: absolute;
		top: 41px;
		right: 0;
		button {
			padding: 8px 30px;
			min-height: 51px;
			min-width: 140px;
			// border: 1px solid #000;
			background: transparent;
			border: 0;
			@include ff_montserrat;
			@include fz(14);
			display: flex;
			align-items: center;
			justify-content: center;

			&::before {
				content: "\f406";
				font-family: $icon;
				@include fz(20);
				margin: 0 .5em 0 0;
			}

			&:focus {
				outline: none;
			}

			&:hover {
				@include flash;
			}
		}
	}

	&__btn {
		a {
			display: inline-block;
			padding: 20px 60px;
			background: #eee;
			border-radius: 5px;
			@include fz(22);
			margin: 0 0 0.3em;
			width: 450px;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			font-weight: 600;
			color: $blue02;
			text-align: center;

			@include xs {
				width: 85%;
				min-width: auto;
				padding: 20px;
				text-align: center;
				display: inline-block;
			}
			span {
				// display: block;
				@include fz(11);
				background: #fff;
				padding: 5px 10px;
				margin: 0 0 0 1em;
				font-weight: normal;
				@include xs {
					display: inline-block;
				}
			}
		}
	}

	&_mail__btn {
		a {
			background: $blue02;
			color: #fff;
			@include fz(15);
			font-weight: normal;
			position: relative;

			&::after {
				@include arrow_r;
				position: absolute;
				right: 20px;
			}
		}
	}
}

@include xs {
	.iziModal-overlay {
		display: none;
	}
}