iframe.wp-embedded-content {
	@include xs {
		width: 100%;
	}
}

@for $i from 2 through 20 {
	@include xs {
		.wp-block-spacer[style="height:#{$i*10}px"] {
			$height: calc( calc($i * 10)/2 );
			height: #{$height}px !important;
		}
	}
}

.wp-block-media-text {
	margin-bottom: 2em;

	&.is-stacked-on-mobile {
		.wp-block-media-text__media {
			@include xs {
				margin: 0 0 1em;
			}
		}
		.wp-block-media-text__content {
			@include xs {
				padding: 0;
			}
		}
	}
}

.wp-block-image {
	margin-bottom: 2em;
	figcaption {
		@include fz(12);
	}
}

.wp-block-embed-youtube {
	.wp-block-embed__wrapper {
		position: relative;
		width: 100%;
		height: 0;
		padding-top: 56.25%;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}
	}
}

.wp-block-embed-instagram {
	&.aligncenter {
		.instagram-media-rendered {
			margin: 0 auto !important;
		}
	}
}


.wp-block-columns {
	display: flex;
	justify-content: space-between;
	// margin: 0 0 2em -4.3%;
	margin: 0 auto 2em;


	@include xs {
		display: block;
		margin-left: 0;
	}

	.wp-block-column {
		// margin-left: 4.3%;
		flex: 1;
		@include xs {
			margin-left: 0;
			margin-bottom: 4em;
			// margin-bottom: 1em;
		}
	}
}


// --------------------------------------------
// Button
// --------------------------------------------
.wp-block-button {
	// margin: 0 0 1em;
	margin: 2em 0;

	.wp-block-button {
		&__link {
			background: #000;
			padding: 1em 3em;
			@include fz(15);
			min-width: 240px;
			color: #fff;
			position: relative;

			&::after {
				@include arrow_r;
				position: absolute;
				right: 1em;
			}
		}
	}
}

.is-content-justification-center {
	text-align: center;
}

.is-style-fill {
	.wp-block-button {
		&__link {
			background: #000;
			padding: 1em 3em;
			@include fz(15);
			min-width: 240px;
		}
	}
}

.is-style-outline {
	.wp-block-button {
		&__link {
			border: 1px solid;
			border-color: #000;
			padding: 1em 3em;
			@include fz(15);
			min-width: 240px;
		}
	}
}

// --------------------------------------------
// font size
// --------------------------------------------

.fz-regular {
	font-size: initial;
}

.has-small-font-size,
.fz-small {
	@include ffz(13, 11);
}

.has-medium-font-size,
.fz-midium {
	@include ffz(20, 18);
}

.has-large-font-size,
.fz-large {
	@include ffz(28, 24);
	line-height: 1.3;
}

.has-huge-font-size, .has-larger-font-size {
	@include ffz(34, 28);
	line-height: 1.3;
}

// --------------------------------------------
// color
// --------------------------------------------
.has-white-color {
	color: #fff;
}