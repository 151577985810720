// snsボタン
.wp_social_bookmarking_light {
	display: flex;
	align-items: center;
	justify-content: center;
	// border: 1px solid #ccc;
	padding: 15px 15px 5px;
	text-align: center;
	// background: #f5f5f5;
	background: -webkit-gradient(linear, left top, right bottom, from(#eee), color-stop(0.25, #eee), color-stop(0.25, white), color-stop(0.5, white), color-stop(0.5, #eee), color-stop(0.75, #eee), color-stop(0.75, white), to(white));
	background: -moz-linear-gradient(-45deg, #eee 25%, white 25%, white 50%, #eee 50%, #eee 75%, white 75%, white);
	background: linear-gradient(-45deg, #eee 25%, white 25%, white 50%, #eee 50%, #eee 75%, white 75%, white);
	background-size: 4px 4px;

	div {
		border: 0;
		padding: 0 !important;
		margin: 0 5px 0 0;
		min-height: 30px;
		line-height: 18px;
		text-indent: 0;
	}

	img {
		border: 0 !important;
		padding: 0;
		margin: 0;
		vertical-align: top !important;
	}

	#fb-root {
		display: none;
	}

	.wsbl_facebook_like iframe{
		max-width: none !important;
	}
	.wsbl_pinterest a{
		border: 0px !important;
	}
}


// 関連記事

.related_post {
	// border: 1px solid #ccc;
	// padding: 20px;

	h3 {
		background: transparent;
		// border-top: 1px solid #ccc;
		// padding: 10px 0 0;
		border-left: 3px solid $blue01;
		padding: 0 0 0 10px;
		margin-top: 30px;
		margin-bottom: 1em;
		@include ffz(18, 16);
		font-weight: 600;
		color: $blue01;
	}

	.crp-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: left;
		// margin-left: -3%;
		margin-bottom: 0;
		border: 1px solid #eee;
		padding: 20px 3% 0 0%;
		@include xs {
			padding: 20px 5% 0 0;
			margin: 0;
		}
	
		li {
			width: 47%;
			margin: 0 0 20px 3%;
			padding: 0;
			list-style: none !important;
			cursor: pointer;

			@include xs {
				width: 45%;
				margin-left: 5%;
				margin-bottom: 20px;
			}

			&:hover {
				@include flash;
			}

			&::before {
				display: none;
			}

			a {
				color: #000;
				display: block;
				&:hover {
					animation: none;
				}
			}

	
			.crp-list-item-image {
				margin: 0 0 2rem;
	
				img {
					margin: 0;
					width: 100%;
					max-width: 100% !important;
					height: auto;
					max-height: 180px;
					object-fit: cover;
				}
			}
		}
	}
}


div#ez-toc-container {
	border: 0 !important;
	border-radius: 0;
	box-shadow: 0 0 0;
	padding: 30px;
	@include ff_montserrat;
	margin: 2em 0 4em 0;
	@include ffz(16, 14);

	@include xs {
		padding: 20px;
	}

	.ez-toc-title-container {
		margin: 0 0 10px;
		padding: 0 0 5px;
		border-bottom: 1px solid #ccc;
	}


	ul.ez-toc-list {
		li {
			&::before {
				display: none;
			}
			
			a {
				color: $link_color;
				position: relative;
				display: block;
				padding: .8em 1.5em;
				margin: 0 0 .3em;
				background: #fff;
				border-radius: 6px;

				&::before {
					// position: absolute;
					// left: 0;
					margin-right: 0.5em;
					color: #000;
				}
	
				&:hover {
					text-decoration: none;
				}
			}

			ul {
				// margin-bottom: 1em;
				li {
					a {
						padding-left: 2em;
					}
				}
			}

			&:last-child {
				ul {
					margin-bottom: 0;
				}
			}
		}
	}
}

.wp-embedded-content {
	margin: 4em 0 0;
	@include sm-lte {
		margin: 2em 0 0;
	}
}



.wp-pagenavi {
	a,
	span {
		@include ffz(15, 13);
		min-width: 2em;
		min-height: 2em;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 0.5em;
	}
}
