.blocks {
	&-service-events {

		&__stack {
			padding: 3em;
			border: 1px solid #ccc;
			border-radius: $border-radius;
			margin: 0 0 .5em;

			&::before {
				display: none;
			}
		}

		&__date {
			@include ffz(15, 13);
			font-weight: bold;
			margin: 0 0 1em;
		}

		&__tag {
			margin-left: 1em;
			font-weight: normal;
			color: $blue01;
			@include ffz(12, 11);
		}

		&__body {
			p {
				margin: 0;
				color: $base-text-color;
			}
		}
	}


	&-service-benefits {
		height: 100%;

		&__wrapper {
			margin: 2em 0;
		}

		&__card {
			background-color: $sub-color;
			padding: 3em;
			border-radius: $border-radius;
			height: 100%;
		}


		&__lead {
			color: $blue04;
			font-weight: bold;
			border-radius: $border-radius $border-radius 0 0;
			position: relative;
			margin: 0 0 1em;
		}

		&__title {
			padding: 0 !important;
			background: transparent;
			color: $base-text-color;
			text-align: left;
			display: flex;
			// flex-direction: column;
			justify-content: flex-start;
			// @include ffz(16, 15);
			margin: 0 0 0.5em;
			line-height: 1.3;
			font-weight: bold;

			&__icon {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 6em;
				color: $blue01;
				font-weight: bold;
				@include fz(13);

				&::after {
					content: "";
					width: 1px;
					height: 100%;
					background: $blue04;
					display: inline-block;
					margin: 0 .5em 0 1em;
				}
			}
		}

		&__media {
			margin: 0 0 2em;
		}

		&__text {
			p:last-child {
				margin: 0;
			}
		}
	}




	&-media-card {
		&__stack {
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 4em 0;

			@include md {
				margin: 2em 0;
				align-items: flex-start;
			}
			
			@include sm-lte {
				flex-direction: column;
			}

			&:nth-child(2n) {
				flex-direction: row-reverse;

				@include sm-lte {
					flex-direction: column;
				}

				.blocks-media-card__body {
					margin: 2.5em -5% 2.5em 0;

					@include md {
						margin-right: -10%;
					}

					@include sm-lte {
						margin: 0;
					}
				}
			}
		}

		&__figure {
			width: 55%;

			@include md {
				width: 70%;
			}

			@include sm-lte {
				width: 100%;
			}
			
			img {
				width: 100%;
				height: auto;
			}
		}

		&__body {
			margin: 2.5em 0 2.5em -5%;
			width: 55%;
			background: rgba(darken($sub-color, 3%), .9);
			border-radius: 10px;
			padding: 30px;
			// color: #fff;
			position: relative;
			z-index: 10;

			@include md {
				width: 60%;
				margin-left: -10%;
				padding: 2.5em;
			}

			@include sm-lte {
				margin: 0;
				width: 100%;
				border-radius: 0 0 10px 10px;
				padding: 2.5em;
			}

			* {
				@include ffz(16, 15);
			}

			*:last-child {
				margin: 0;
			}
		}
	}
}


.block-service-title {
	// border: solid #ccc;
	// border-width: 0 0 0 1px;
	// padding: 0 0 0 40px;

	&__l {
		@include ffz(28, 24);
		color: $base-text-color;
		margin: 0;
	}

	&__s {
		@include ffz(15, 12);
		color: #999;
		margin-top: 1em;
	}

}

.block-member-card {
	border: 0 solid #ccc;
	border-width: 0 0 0 1px;
	padding: 20px 20px 20px 40px ;

	&-title__name {
		background: none;
		padding: 0 !important;
		margin: 1em 0 .5em;
		@include fz(22);
		display: flex;
		align-items: center;
		gap: 20px;
	}

	&-title__en {
		@include fz(11);
	}

	&-career {
		@include fz(12);
		margin: .5em 0;
		color: #999;
	}

	&__body {
		p {
			@include fz(14);
			line-height: 1.5;
		}
	}

	&__footer {
		margin: 2em 0 0;
	}

	&-achievement {

		&-title {
			@include fz(13);
			margin: 0 0 .5em;
			background: $blue03;
			color: #fff;
			padding: .3em .8em;
			display: inline-block;
			font-weight: normal;
		}

		p {
			margin: 0 0 .8em;
			@include fz(13);
			line-height: 1.5;
		}
	}
}