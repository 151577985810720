@charset "utf-8";

// ----------------------------------------------------------------------
// font family
// ----------------------------------------------------------------------

@mixin noto {
	font-family: $noto , メイリオ, Meiryo, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}

@mixin yugo {
	font-family: 游ゴシック体, $yugo, 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, sans-serif;
}
@mixin hiragino-go {
	font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
}

@mixin yumin {
	font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
@mixin hiragino-min {
	font-family: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
}

@mixin garamond {
	font-family: 'EB Garamond', serif;
}


@mixin lato {
	font-family: $lato, sans-serif;
}

@font-face {
font-family: さわらびゴシック;
src: url('https://cdn.leafscape.be/sawarabi/sawarabi-gothic-medium_web.woff2')
     format("woff2");
}
    