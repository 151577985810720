.header {
	position: fixed;
	z-index: 100;
	background: #ffffff;
	width: 100%;
	height: 108px;
	top: 0;


	@include sm {
		height: 83px;
	}

	@include xs {
		min-width: 320px;
		height: 82px;
	}

	&__inner {
		width: 95%;
		// max-width: 1200px;
		// min-width: 1000px;
		margin: 0 auto;
		padding: 20px 0 30px 0;

		@include sm-lte {
			min-width: auto;
			padding: 10px 4% 20px 4%;
			width: 100%;
			position: relative;
		}
	}

	&-description {
		@include fz(11);
		margin: 0 0 5px;

		@include xs {
			@include fz(10);
			margin: 0 0 10px;
			width: calc(100% - 50px);
		}
	}

	&.fixed {
		@include device-above( $device-md ) {
			height: auto;
	
			.header__inner {
				width: 95%;
				margin: 0 auto;
				padding: 15px 0;
			}
	
			.header-description {
				display: none;
			}
	
			.header-logo {
				width: 230px;
				img {
					width: auto;
				}
			}
		}

	}


	&-container {
		display: flex;
		justify-content: space-between;
		align-items: center;


		@include sm-lte {
			display: block;
		}
	}


	&-logo {
		width: 240px;



		@include xs {
			width: 60%;
			min-width: 250px;

			img {
				height: 28px;
				width: auto;
			}
		}

		@include md {
			width: 200px;
			margin-right: 10px;
		}
	}

	&-nav {
		display: block;

		@include md-gt {
			display: block !important;
			opacity: 1 !important;
		}

		@include sm-lte {
			display: none;
			background: #fff;
			position: fixed;
			top: 82px;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			padding: 20px 4% 150px;
			overflow: auto;
		}

		&__wrapper {
			@include sm-gt {
				flex: 1;
			}

			@include sm-lte {
				height: 100%;
			}
		}

		&__inner {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			@include sm-lte {
				display: block;
			}
		}


		&-list {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			@include sm-lte {
				display: block;
				margin: 0 0 30px;
			}

			&.__contact {
				@include md {
					align-items: start;
					position: absolute;
					top: 0;
				}
			}


			&__item {
				margin-left: 3em;
				font-size: px--vw(14);
				letter-spacing: .1em;
				position: relative;

				@include xl {
					@include fz(14);
				}

				@include md {
					margin-left: 2.5em;
					@include fz(13);
				}

				@include sm-lte {
					margin: 0;
					display: block;
					border-bottom: 1px solid #ccc;
					
					&:first-child {
						border-top: 1px solid #ccc;
					}
				}

				@include sm-lte {
					@include fz(14);
				}
			}

			&-inquiry {
				display: flex;
				border: 1px solid $main-color;
				border-radius: 3px;
				margin: 0 10px 0 40px;

				@include sm-lte {
					margin: 0 0 10px;
				}

				&__item {
					position: relative;

					@include sm-lte {
						width: 50%;
					}

					a {
						display: block;
						text-align: center;
						padding: 13px 30px;
						@include fz(14);
						color: $main-color;
						overflow: hidden;
						position: relative;
						z-index: 1;

						@include xs {
							padding: 18px 30px;
						}


						&:hover {
							background: $main-color;
							color: #fff;
						}

					}

					&:not(:last-child) {
						&::after {
							content: "";
							display: block;
							width: 1px;
							height: 25px;
							position: absolute;
							background: $main-color;
							right: 0px;
							top: 50%;
							transform: translateY(-50%);
						}
					}

				}

				&:hover {
					li::after {
						display: none;
					}
				}
			}

			&-contact {
				a {
					display: block;
					background: $main-color;
					padding: 13px 30px;
					color: #fff;
					@include fz(14);
					border-radius: 3px;
					text-align: center;

					@include xs {
						padding: 18px 30px;
					}
				}
			}

			.contact,
			.inquiry {
				margin-left: 5px;
				letter-spacing: .05em;

				a {
					border: 1px solid #000;
					padding: .5em 1.2em;
					text-align: center;
					// background: #9f9f9f;
					// border-radius: $border-radius;
					// color: #fff;

					@include md {
						border-width: 0 1px 1px;
						padding: .6em 1.5em;
					}
				}

				// @include sm-lte {
				// 	display: none;
				// }
			}

			.inquiry {
				margin-left: 35px;

				@include md {
					margin-left: 20px;
				}
			}
		}

		&__link {
			color: #000;
			display: inline-block;
			padding: 10px 0;
			position: relative;

			@include sm-lte {
				padding: 20px;
				display: block;
				&::after {
					@include arrow_r;
					position: absolute;
					right: 20px;
				}
			}
		}

		
	}

	&-nav-subnav {
		display: none;
		position: absolute;
		width: 300px;
		background: rgba($blue02, .96);
		// padding: 2em;
		margin: .5em 0 0 -1em;
		// border-radius: 10px;
		overflow: hidden;

		@include sm-lte {
			display: block !important;
			position: initial;
			width: 100%;
			border-radius: 0;
			margin: 0;
			padding: 0 20px 20px;
			background: transparent;
		}

		&.show {
			display: block;
		}

		&.hidden {
			display: none;

			@include sm-lte {
				display: block;
			}
		}

		// &::before {
		// 	content: "";
		// 	display: block;
		// 	width: 1px;
		// 	height: 1em;
		// 	background: $blue02;
		// 	position: absolute;
		// 	top: -1em;
		// 	left: 2em;
		// }

		&-list {
			&__item {
				&:not(:last-child) {
					border: solid darken($blue02, 10%);
					border-width: 0 0 1px;

					@include sm-lte {
						border-color: #ccc;
					}
				}
			}

		}
		
		&__link {
			color: #fff;
			display: block;
			padding: 1.5em 2em;

			@include sm-lte {
				background: #eee;
				color: $base-text-color;
				padding: 1em 1.5em;
				@include fz(12);
				position: relative;


				&::after {
					@include arrow_r;
					position: absolute;
					right: 20px;
					color: #aaa;
				}
			}

			&:hover {
				background: darken($blue02, 8%);
				color: #fff;
			}

		}
	}


	&-nav-icon {
		display: none;
		@include sm-lte {
			display: inline-block;
			position: absolute;
			top: 50%;
			right: 4%;
			transform: translateY(-60%);
		}
	}

	.header-nav-toggle,
	.header-nav-toggle span {
		display: inline-block;
		transition: all .4s;
		box-sizing: border-box;
	}
	.header-nav-toggle {
		position: relative;
		width: 38px;
		height: 18px;
		z-index: 1000;


		&::after {
			position: absolute;
			left: 0;
			bottom: -18px;
			content: 'MENU';
			display: block;
			width: 100%;
			color: #000;
			text-decoration: none;
			text-align: center;
			transition: all .4s;
			@include ff_montserrat;
			@include fz(11);
			font-weight: 500;
			letter-spacing: 0.1em;
		}

		span {
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: #000;
			//   border-radius: 4px;

			&:nth-of-type(1) {
				top: 0;
			}

			&:nth-of-type(2) {
				top: 8px;
			}

			&:nth-of-type(3) {
				bottom: 0;
			}
		}

		&.active {
			&::after {
				content: 'CLOSE';
				bottom: -18px;
				letter-spacing: 0;
			}

			span {
				&:nth-of-type(1) {
					-webkit-transform: translateY(8px) rotate(-25deg);
					transform: translateY(8px) rotate(-25deg);
				}
				&:nth-of-type(2) {
					opacity: 0;
				}
				&:nth-of-type(3) {
					-webkit-transform: translateY(-8px) rotate(25deg);
					transform: translateY(-8px) rotate(25deg);
				}
			}
		}
	}


	&-nav-contact {
		display: none;

		@include sm-lte {
			display: block;
		}

		h2 {
			@include fz(14);
			margin: 0 0 10px;
			text-align: center;
		}

		&__box {
			margin: 0 0 .5em;
			// border: 1px solid #eee;
			// padding: 20px
		}

		&_tel {
			margin: 0 0 2em;
		}

		&_tel__btn {
			a {
				display: block;
				padding: 15px 60px;
				background: #eee;
				border-radius: 5px;
				@include fz(20);
				margin: 0 0 0.3em;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				font-weight: 600;
				color: $blue02;
				text-align: center;
				width: 100%;
				span {
					@include fz(10);
					background: #fff;
					padding: 5px 10px;
					font-weight: normal;
					margin: 0 10px;
				}
			}
		}

		&_mail__btn {
			a {
				background: $blue02;
				color: #fff;
				font-weight: normal;
				position: relative;
				display: block;
				padding: 20px;
				border-radius: 5px;
				text-align: center;
				@include fz(14);
				letter-spacing: .1em;
				&::after {
					@include arrow_r;
					position: absolute;
					right: 20px;
				}
			}
		}

		&_inquiry__btn {
			margin: 0 0 2em;
			a {
				background: #fff;
				color: $blue02;
				border: 1px solid $blue02;
				font-weight: normal;
				position: relative;
				display: block;
				padding: 20px;
				border-radius: 5px;
				text-align: center;
				@include fz(14);
				letter-spacing: .1em;
				&::after {
					@include arrow_r;
					position: absolute;
					right: 20px;
				}
			}
		}
	}
}






