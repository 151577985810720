@charset "UTF-8";
@import "_variables";

html, body {
	width: 100%;
	// height: 100%;
	margin: 0;
	padding: 0;
	min-width: 320px;
	font-size: 10px;
}

* {
	box-sizing: border-box;
}


body {
	color: $base-text-color;
	font-family: $base-font-family;
	font-size: 10px;
	line-height: 1.5;
	// background: #ffffff;
	// height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	-webkit-text-size-adjust: 100%;
	position: relative;
}

a {
	color: $link-color;
	text-decoration: none;
	// transition: color .3s;

	&[href^="tel:"] {
		pointer-events: none;
		color: #000;
		@include xs {
			pointer-events: auto;
		}
	}

	&.icon-arrow-r {
		// position: relative;
		&::after {
			@include arrow_r;
			// position: absolute;
			// right: 1em;
		}
	}
}

a:hover {
	color: $link-hover-color;
	text-decoration: none;
}

ul, ol, li, h1, h2, h3, h4, h5, h6,div,p {
	margin: 0;
	list-style: none !important;
}


.bold {
	font-weight: bold;
}

.caution {
	@include fz(12);
	font-family: $base-font-family;
	text-indent: -1em;
	padding-left: 1em;
	display: inline-block;
}

img {
	vertical-align: middle;
	max-width: 100%;
	height: auto;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.blank {
	pointer-events: none;
}

/* ONLY----------------------- */
.sp_only {
	display: none;
	@media screen and (max-width: 736px) {
		display: inline-block;
	}
}

.pc_only {
	display: inline-block;
	@media screen and (max-width: 736px) {
		display: none;
	}
}

.visible-xs {
	display: none;
}
@include xs {
	.visible-xs {
		display: inherit;
	}
	.hidden-xs {
		display: none;
	}
}

@include sm {
	.visible-xs {
		display: none;
	}
}


/* hover ---------------------- */
a:hover {
	opacity: 1;
	-webkit-animation: flash 1.3s;
	animation: flash 1.3s;
}
@-webkit-keyframes flash {
	0% {
		opacity: .3;
	}
	100% {
		opacity: 1;
	}
}
@keyframes flash {
	0% {
		opacity: .3;
	}
	100% {
		opacity: 1;
	}
}

/* common ---------------------- */

.clearfix {
	display: flow-root;
}

.mb0 { margin-bottom: 0 !important; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }
.mb35 { margin-bottom: 35px; }
.mb50 { margin-bottom: 50px; }
.mb80 { margin-bottom: 80px; }

.mt0 { margin-top: 0; }
.m0 { margin: 0; }

.mb-s {
	margin-bottom: 10px;
}
.mb-m {
	margin-bottom: 30px;
	@include xs {
		margin-bottom: 20px;
	}
}
.mb-l {
	margin-bottom: 60px;
	@include xs {
		margin-bottom: 40px;
	}
}

.mb-05em { margin-bottom: .5em; }
.mb-1em { margin-bottom: 1em; }
.mb-2em { margin-bottom: 2em; }
.mb-3em { margin-bottom: 3em; }
.mb-4em { margin-bottom: 4em; }
.mb-5em { margin-bottom: 5em; }


.p10 {	padding: 10px; }
.p20 {	padding: 20px; }
.p30 {	padding: 30px; }
.p40 {	padding: 40px; }

b {
	font-weight: 600;
}


/* ----------------------------- */
.align_c {
	text-align: center;
}



.box_w {
	width: 90%;
	max-width: 1100px;
	margin: 0 auto;
	@include xs {
		width: 90%;
	}
}



body.fixed {
	position: fixed;
	height: 100%;
	overflow: hidden;
}



// ----------------------------------------------------------
// color
// ----------------------------------------------------------
.red,
.c_red {
	color: $red;
}