@charset "UTF-8";

main {
	margin-bottom: 100px;
	article {
		// max-width: 720px;
		max-width: 1000px;
		width: 90%;
		margin: 0 auto;
		.home & {
			width: 800px;
		}


		@include xs {
			width: 90%;
			margin: 0 auto;
		}
	}

}


.js-fadein {
  opacity: 0;
  transform : translate(0, 50px);
  transition : all 1500ms;
}

.js-fadein.scrollin{
  opacity: 1;
  transform: translate(0, 0);
}



.page_navi {
	text-align: center;
	margin-top: 50px;
	@include ff_montserrat;

	.paging {
		div {
			display: inline-block;
			margin-right: 5px;

			@include xs {
				display: block;
				margin-right: 0;
			}
			a {
				position: relative;
				border: 1px solid #000;
				color: #000;
				display: block;
				@include fz(15);

				&::before {
					position: absolute;
					font-family: $icon;
					line-height: 0.5;
					transform: translateY(-50%);
				}

				@include xs {
					display: block;
					width: 92%;
					margin: 0 auto 10px;
				}
			}

			&.next {
				margin-right: 0;
				a {
					padding: 10px 30px 10px 20px;
					&::before {
						top: 50%;
						right: 10px;
						content: "\f284";
					}
				}
			}

			&.prev {
				a {
					padding: 10px 20px 10px 30px;
					&::before {
						top: 50%;
						left: 10px;
						content: "\f282";
					}
				}
			}

			&.archive a {
				padding: 10px 20px;
			}
		}
	}
}


.btn_block {
	padding: 40px 0;
	text-align: center;
	a {
		display: inline-block;
		background: #000;
		color: #ffffff;
		padding: 10px 40px;
		min-width: 240px;
		font-weight: 300;
		position: relative;
		@include ffz(16, 14);

		&:after {
			content: "\f3d1";
			font-family: $icon;
			color: #fff;
			position: absolute;
			top: 50%;
			right: 20px;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
		}

		@include xs {
			@include fz(13);
		}
	}
}
