h1, .ttl01, .title__h1 {
	@include ffz(28, 20);
	font-weight: 600;
	margin-bottom: 1.5em;
	// margin: 1.5em 0 0;
	vertical-align: middle;
	letter-spacing: .1em;

	@include xs {
		margin-bottom: 20px;
	}

	span.supplement {
		@include fz(14);
		margin-left: 5px;
		color: #999999;
	}
}
h2, .ttl02, .title__h2 {
	padding: 15px 0 0 !important;
	border-top: 1px solid #000;
	// border-bottom: 1px solid #000;
	@include ffz(22, 18);
	font-weight: bold;
	margin-top: 2.5em;
	letter-spacing: .1em;

}
h3, .ttl03, .title__h3 {
	padding: 10px 20px!important;
	background: #e0e0e0;
	// background: #0b4279;
	color: #000;
	font-weight: bold;
	margin-top: 2em;
	letter-spacing: 0.1em;
	@include ffz(20, 17);

	@include xs {
		font-weight: 500;
	}
}

h4, .ttl04, .title__h4 {
	// padding-bottom: 5px;
	@include ffz(18, 15);
	// padding: 0 0 8px !important;
	margin-top: 2em;
	font-weight: bold;
	position: relative;
	// color: $blue03;
	// border-bottom: 2px solid #ddd;
	color: $blue03;
	// @include fz(15);

	// &::after {
	// 	content: "";
	// 	position: absolute;
	// 	left: 0;
	// 	bottom: 0;
	// 	width: 100%;
	// 	height: 10px;
	// 	background: -webkit-repeating-linear-gradient(-45deg, #e5e5e5, #e5e5e5 1px, transparent 1px, transparent 6px);
	// 	background: repeating-linear-gradient(-45deg, #e5e5e5, #e5e5e5 1px, transparent 1px, transparent 3px);
	// 	z-index: -10;
	// }

	// @include xs {
	// 	padding-left: 10px;
	// }
}

h5, .ttl05, .title__h5 {
	// display: inline-block;
	border-left: 3px solid #e5e5e5;
	padding: 0 17px !important;
	margin-top: 1em;
	font-weight: bold;
	line-height: 1.5;
	@include ffz(18, 15);
}

h6, .ttl06, .title__h6 {
	color: $blue02;
	margin-top: 1em;
	@include fz(15);
	font-weight: 600;
	letter-spacing: .1em;
}

p {
	margin-top: 1.5em;
	line-height: 1.85;
	letter-spacing: 0.09em;
	@include ffz(18, 15);

	.single-column & {
		padding: 0;
		@include xs {
			padding: 0;
		}
	}
}





.wp-block-quote,
blockquote {
	margin: 1em 5px 20px;
	padding: 20px 20px 20px 40px;
	border: solid $blue01;
	border-width: 0 0 0 1px;
	background: #f9f9f9;
	position: relative;
	// font-style: oblique;
	@include fz(13);
	line-height: 1.3;
	&::before {
		content: "\f1f5";
		font-family: $icon;
		position: absolute;
		top: 20px;
		left: 20px;
		color: $blue01;
		@include fz(14);
	}

	p {
		margin-top: 10px;
	}

	// p:first-child {
	// 	margin: 0;
	// }

	cite {
		@include fz(12);
		margin: .5em 0 0;
		display: inline-block;
	}

	&.is-large {
		margin: 1em 5px 20px;
		padding: 20px 20px 20px 40px;
		p {
			@include fz(20);
		}

		cite {
			margin: .5em 0 0;
		}
	}

	&.wp-block-pullquote {
		padding: 20px 20px 20px 40px;

		p {
			@include fz(22);
		}
		cite {
			margin: .5em 0 0;
			@include fz(14);
			letter-spacing: .05em;
		}
	}
}

.wp-block-pullquote {
	&.alignleft {
		float: left;
		margin: 1em 3rem 2rem 0;

		& + p {
			color: #999;
		}

	}

	&.alignright {
		float: right;
		text-align: left;
		margin: 1em 0 2rem 3rem;
	}

	& + p {
		margin: calc(1em + 20px) 0;
		@include fz(13);
	}
}


pre,
.wp-block-preformatted {
	@include fz(14);
}


strong {
	font-weight: bold;
}

.bg_yellow,
.marker_y {
	background: #fff59b;
}

.red {
	color: $red;
}

.red_darker {
	color: $red_darker;
}


.blue {
	color: $blue03;
}

.mincho {
	@include yumincho;
}

.underline {
	// text-decoration: underline;
	// text-decoration-color: $red;
	background: linear-gradient(transparent 60%, rgba($red, 0.35) 40%);;
}

.border__gray__10 {
	border: 10px solid #f5f5f5;
}




/* margin --------------------- */
.mb-s {
	margin-bottom: 10px;
}

.mb-m {
	margin-bottom: 30px;
	@include xs {
		margin-bottom: 20px;
	}
}
.mb-l {
	margin-bottom: 60px;
	@include xs {
		margin-bottom: 30px;
	}
}

.mb-ll {
	margin-bottom: 100px;
	@include xs {
		margin-bottom: 50px;
	}
}


ul {
	margin-top: 2em;
	letter-spacing: .05em;
	li {
		@include ffz(18, 16);
		list-style: none;
		margin-bottom: .3em;
		padding-left: 2em;

		&::before {
			content: "●";
			@include fz(10);
			// margin-right: 10px;
			color: $blue03;
			// text-indent: -2em;
			margin-right: 1em;
			margin-left: -2em;
			width: 0;
		}

		ul {
			margin: .5em 0 .5em 2rem;
			li {
				margin-bottom: .3em;
				&::before {
					content: "●";
					@include fz(10);
					margin-right: 10px;
					color: #aaaaaa;
				}
			}
		}
	}

	&.wp-block-gallery  {
		li {
			padding: 0;
			
			&::before {
				display: none;
				content: "";
				margin: 0;
			}
		}
	}
}

ul.link {
	margin-top: 2em;
	letter-spacing: .05em;
	li {
		display: inline;
		margin-right: 30px;
		padding-left: 1em;

		&::before {
			content: "\f283";
			font-family: $icon;
			@include fz(13);
			margin-right: 10px;
			margin-left: -1em;
		}

		@include xs {
			display: block;
		}
	}
}


// -------------------------------------------------------
//	single_header
// -------------------------------------------------------
.single_header {
	margin: 0 0 20px;
	padding: 30px;
	// border: 1px solid #ccc;
	box-shadow: 0 0 15px rgba(#000, .13);
	border-radius: $border-radius;

	@include xs {
		border: 0;
		padding: 3em;
	}

	&__title {
		margin-bottom: 10px;
		font-weight: bold;
		@include xs {
			margin-bottom: 10px;
		}
	}

	time {
		// font-weight: 600;
		@include ffz(13, 11);
		margin: 0 0 .5em;
		display: inline-block;
	}

	// &__data {
	// 	display: flex;
	// 	@include xs {
	// 		display: block;
	// 	}
		
	// 	time {
	// 		margin-right: 2em;
	// 		@include xs {
	// 			margin-bottom: 5px;
	// 			display: block;
	// 		}
	// 	}
	// }

	ul.tag {
		margin: 0;
		display: flex;
		
		@include xs {
			display: block;
		}
		li {
			padding: 0;
			margin-right: 10px;
			display: inherit;
			letter-spacing: 0;
			@include ff_montserrat;
			@include ffz(12, 11);
			letter-spacing: .1em;
			// border: 1px solid #ccc;
			@include xs {
				padding: 0;
				margin-right: 10px;
				display: inline-block;
			}
		}
	}
}



ul.tag {
	margin-top: 2em;
	li {
		display: inline;
		@include fz(11);
		padding: 1px 5px 2px 5px;
		line-height: 1;
		letter-spacing: 0.1em;
		margin-right: 3px;

		a {
			// color: #fff;
			color: $blue03;
			&::before {
				content: "#";
			}
		}
		&::before {
			content: "";
			margin: 0;
			padding: 0;
			text-indent: 0;
		}
	}
}

ol {
	margin-top: 2em;
	counter-reset: ol_list;
	@include ffz(18, 16);
	letter-spacing: .05em;
	li {
		list-style: none;
		margin-bottom: .3em;
		padding-left: 2em;
		&::before {
			counter-increment: ol_list;
			content: counter(ol_list, decimal-leading-zero)".";
			color: $blue03;
			@include ff_montserrat;
			font-weight: bold;
			// padding-left: 30px;
			// text-indent: -30px;
			text-indent: -2em;
			display: inline-block;
			width: 0;
		}

		ol {
			margin-left: 30px;
			counter-reset: ol_list;
			margin-top: 1em;
			li {
				margin-bottom: .3em;
				padding-left: 2em;
				&::before {
					counter-increment: ol_list;
					content: counter(ol_list, decimal-leading-zero)".";
					color: #aaa;
					@include ff_montserrat;
					font-weight: bold;
					text-indent: -2em;						
					display: inline-block;
					width: 0;
				}
			}
		}
	}
}

figure,
.wp-block-image,
.wp-block-embed  {
	margin: 1.5em 0;

	figcaption {
		@include fz(14);
	}

	&.alignleft {
		float: left;
		margin: 1.5rem 3rem 0 0;
	}

	&.alignright {
		float: right;
		margin: 1.5rem 0 0 3rem;
	}
}


.wp-block-table {
	margin-top: 3em;
	table {
		margin: 0;
	}
}

.wp-block-table table,
table {
	width: 100%;
	// margin-bottom: 40px;
	vertical-align: middle;
	// @include ffz(16, 13);
	@include ffz(18, 16);
	margin: 0 0 1em;

	caption {
		font-weight: bold;
		color: $blue03;
		margin-bottom: 10px;
	}

	thead {
		tr {
			th {
				padding: 1em 1.5em;
				background: #f1f1f1;

				@include sm-lte {
					padding: .8em 1em;
				}
			}
		}
	}

	tbody {
		tr {
			vertical-align: middle;
			th {
				background: #eae9e5;
				padding: 1em 1.5em;
				line-height: 1.3;
				border: solid #c5c4c1;
				border-width: 1px 0;
				width: 25%;
				vertical-align: middle;
				font-weight: bold;

				@include sm-lte {
					padding: .8em 1em;
				}

				@include xs {
					display: block;
					width: 100% !important;
				}
			}
			td {
				border: solid #eae9e5;
				border-width: 1px 0;
				// border-top: 1px solid #eae9e5;
				// border-bottom: 1px solid #e0dfd9;
				padding: 1em 1.5em;
				vertical-align: middle;

				@include sm-lte {
					padding: .8em 1em;
				}

				@include xs {
					display: block;
					// border: 0;
					border-width: 0 0 1px;
	
					&:last-child {
						border-bottom: 1px solid #e0dfd9;
					}
				}

				*:first-child {
					margin-top: 0;
				}

				*:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.wp-block-table {
	margin-bottom: 4em;
	
	&.table__fixed {
		overflow-x: scroll;

		@include xs {
			overflow-x: scroll;
			white-space: nowrap;
			-webkit-overflow-scrolling: touch;
		}

		&::before {
			@include xs {
				content: "→ 横にスクロールします";
				@include fz(11);
				margin: 0 0 1em;
				color: #999;
				display: block;
			}
		}

		table {
				table-layout: fixed;
				min-width: 100%;
				margin: 0;
				@include xs {
					width: auto;
				}

				th {
					@include xs {
						display: table-cell;
						width: 20%;
						min-width: 20%;
						padding: 1em;
					}
				}

				td {
					@include xs {
						padding: 1em;
						display: table-cell;
						width: auto;
						min-width: 8em;
						// white-space: nowrap;
						

						// &:last-child {
						// 	border-bottom: 0 solid #e0dfd9;
						// }
					}
				}
		}
	}
}




.block_border__gray {
	border: 3px solid #e5e5e5;
	padding: 40px !important;

	p:last-child {
		margin: 0;
	}

	@include xs {
		padding: 20px !important;
	}
}

.link_btn_block {
	text-align: center;
	margin-bottom: 0;
	li {
		display: inline-block;
		margin: 0 15px 30px;
		padding-left: 0;
		&::before {
			content: "";
			margin: 0;
			padding: 0;
			text-indent: 0;
		}
		
		@include xs {
			margin: 0 15px 15px;
		}

		a {
			display: inline-block;
			position: relative;
			min-width: 300px;
			background: $blue02;
			padding: 20px 2em;
			color: #fff;
			border-radius: 10px;


			&::before {
				content: '';
				position: absolute;
				text-align: center;
				margin-top: -7px;
				right: 20px;
				top: 50%;
				width: 8px;
				height: 8px;
				border-top: 2px solid #fff;
				border-right: 2px solid #fff;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);

				@include xs {
					margin-top: -5px;
					width: 10px;
					height: 10px;
				}
			}

			@include xs {
				padding: 15px;
			}
		}
	}
}


.link_btn_block02 {
	text-align: center;
	margin-bottom: 0;
	li {
		display: inline-block;
		margin: 0 15px 30px;

		@include xs {
			margin: 0 15px 15px;
		}

		&::before {
			content: "";
			margin: 0;
			padding: 0;
			text-indent: 0;
		}
		a {
			display: inline-block;
			position: relative;
			min-width: 250px;
			background: transparent;
			padding: 20px;
			color: $blue02;
			border: 1px solid $blue02;
			&::before {
				content: '';
				position: absolute;
				text-align: center;
				margin-top: -7px;
				right: 20px;
				top: 50%;
				width: 14px;
				height: 14px;
				border-top: 1px solid $blue02;
				border-right: 1px solid $blue02;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}
	}
}

.summary_block {
	margin: 50px 0;
	border: 4px solid #eae9e5;
	padding: 20px;
	color: #333;
	.summary_ttl {
		border-bottom: 2px solid #eae9e5;
		padding-bottom: 15px;
		@include fz(18);
		font-weight: bold;
	}
}

.column_block {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	margin: 3em 0 0;

	@include xs {
		display: block;
	}

	& > div p:last-child {
		margin-bottom: 50px;
		@include xs {
			margin-bottom: 40px;
		}
	}

	a.box_link {
		display: block;
		color: #000;
		border: 1px solid #ccc;
		padding: 2em;
		margin-bottom: 15px;
		border-radius: $border-radius;
		overflow: hidden;

		.more_txt {
			text-align: right;
			span {
				background: #000;
				color: #ffffff;
				display: inline-block;
				@include fz(11);
				font-weight: 200;
				padding: 5px 5px;
				line-height: 1;
			}
		}

		ul.tag {
			li {
				a {
					display: inline;
				}
			}
		}
	}

	figure {

		@include xs {
			display: flex;
			align-items: flex-start;
		}


		img {
			margin-bottom: 15px;

			@include xs {
				width: 40%;
				height: auto;
				-o-object-fit: contain;
				object-fit: contain;
				margin-right: 15px;
			}
		}
		figcaption {
			font-weight: bold;
			margin-bottom: 10px;
			@include ffz(18, 16);
			letter-spacing: .06em;
			line-height: 1.4;
		}

		time {
			display: block;
			color: #999;
			font-weight: normal;
			margin: 0 0 0.5em;
		}

		&+p {
			@include fz(12);
			line-height: 1.5;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.post-type-archive-column &,
	.category & {
		figure {
			img {
				// height: 118px;
				max-height: 170px;
				width: 100%;
				object-fit: cover;

				@include xs {
					width: 40%;
					height: auto;
					-o-object-fit: contain;
					object-fit: contain;
					margin-right: 15px;
				}
			}
		}
	}
}

.column {

	&_2,
	&__2 {
		.column_box {
			@include calc(width,'96%/2');
			position: relative;
			z-index: 0;
			margin-bottom: 30px;
			@include xs {
				width: 100%;
			}
		}
	}

	&_3,
	&__3 {
		.column_box {
			@include calc(width,'96%/3');
			@include xs {
				width: 100%;
			}
			&:nth-child(3n+2):last-child {
				margin-left: auto;
				margin-right:34%;
			}
		}

	}

	&__4 {
		margin-bottom: 1em;
		@include xs {
			display: flex;
			width: 100%;
		}
		.column_box {
			width: calc(96%/4);
			margin-bottom: 1em;
			@include xs {
				width: calc(98%/2);
				margin-bottom: 10px;
			}
		}
	}
}

.twitter-tweet {
	margin: 30px auto !important;
}

time {
	@include ff_montserrat;
	@include fz(12);
	letter-spacing: 0.1em;
}

a.flag {
	padding-top: 100px;
	margin-top: -100px;
	height: 0;
	display: inline-block;
}


// インタビュー
.ttl_interview {
	@include fz(18);
	color: #0b4279;
	font-weight: 600;
	margin-bottom: 20px;
	// padding-left: 40px;
	line-height: 1.6;
	@include xs {
		margin-bottom: 10px;
	}
}

.ttl_interview_q {
	@include ffz(18, 16);
	color: #0b4279;
	font-weight: 600;
	margin-bottom: 20px;
	padding-left: 30px;
	line-height: 1.6;
	letter-spacing: .05em;
	padding: 0 30px;
	background: none;
	@include xs {
		margin-bottom: 10px;
		letter-spacing: 0;
		@include fz(16);
	}

	&::before {
		content: 'Q.';
		// background: #0b4279;
		// color: #ffffff;
		color: #0b4279;
		// @include fz(16);
		// border-radius: 50%;
		width: 30px;
		// border: 2px solid #0b4279;
		display: inline-block;
		@include ff_montserrat;
		// vertical-align: middle;
		margin-left: -30px;
	}
}

.ph_block {
	margin-bottom: 20px;

	.single-column & {
		text-align: center;
	}
}


// 記事を書いた人
.editor_profile {
	border: 3px solid #f5f5f5;
	padding: 20px;
	margin-bottom: 20px;

	a {
		display: block;
	}

	h5 {
		@include fz(11);
		border: 0;
		border-bottom: 2px solid #f5f5f5;
		margin-bottom: 20px;
		padding: 0 0 15px 0;
		display: block;
	}

	.profile_block {
		display: flex;

		.profile_avator {
			width: 100px;
			margin-right: 20px;
			img {
				border-radius: 50%;
			}
			@include xs {
				width: 60px;
				margin-right: 10px;
			}
		}

		.profile_info {
			flex: 1;

			h6 {
				margin-bottom: 10px;
				display: table;
				vertical-align: middle;
				@include xs {
					display: block;
				}
				span {
					color: #aaa;
					@include fz(10);
					// margin-left: 10px;
					padding-left: 10px;
					font-weight: normal;
					display: table-cell;
					vertical-align: middle;

					@include xs {
						display: block;
						padding-left: 0;
					}
				}
			}

			p {
				padding: 0;
				margin: 0;
				@include fz(11);
				color: #000;
			}
		}

	}
}

.aligncenter {
	text-align: center;
}

.alignright {
	text-align: right;
}


picture {
	display: inline-block;
	margin-bottom: 2em;
	&.article_hero {
		margin-bottom: 3em;
	}
}

.block-border-left__gray {
	border: solid #ccc;
	border-width: 0 0 0 1px;
	padding: 20px 0 20px 40px;

	@include xs {
		padding: 0 0 0 30px;
	}
}