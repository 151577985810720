.topicpath {
	max-width: 1000px;
	width: 90%;
	margin: 0 auto 30px;
	@include ffz(13, 11);

	.single_column & {
		width: 800px;
	}

	@include xs {
		display: none;
	}
	ul {
		list-style: none;
		text-align: right;
		li {
			display: inline;
			list-style: none;
			position: relative;
			padding: 0;
			&::before {
				content: ' ';
				margin: 0;
				padding: 0;
				text-indent: 0;
			}

			&::after {
				content: '\0bb';
				vertical-align: middle;
				margin: -5px 10px 0;
				@include fz(13);
				display: inline-block;

			}

			&:last-child {
				&::after {
					content: ' ';
					margin: 0;
				}
			}
		}
	}
}
