#sidebar {
	// width: 220px;
	width: 100%;
	// margin-left: 60px;
	background: #f1f1f1;
	position: relative;
	left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
	width:100vw; 
	padding: 4rem 0;

	@include sm-gt {
		.post-type-column & {
			width: 28%;
			// margin-left: 60px;
			background: transparent;
			padding: 0;
			margin: 0;
			left: auto;
			right: auto;
		}
	}

	@include xs {
		width: 100%;
		padding: 4em 0 1px;
	}

	.sidebar_box {
		max-width: 1000px;
		width: 90%;
		margin: 0 auto;

		@include sm-gt {
			.post-type-column & {
				width: 100%;
				margin: 0 auto 0 0;
				// padding: 0 30px;
			}
		}

		p {
			@include ff_montserrat;
			margin-bottom: 20px;
			color: $blue03;
			@include ffz(15, 13);
			font-weight: 600;
			position: relative;
			padding: 0 0 0 2em;

			&::before {
				content: "";
				display: inline-block;
				width: 1.3em;
				height: 2px;
				background: $blue01;
				position: absolute;
				top: calc(50% - 1px);
				left: 0;
			}

			@include xs {
				border: 0;
			}
		}
	}

	.category_block {
		ul {
			li {
				a {
					&:before {
						content: "#";
						margin-right: 5px;
						display: none;
					}
					&:after {
						display: none;
					}
				}
			}
		}
	}

	ul {
		// background: $blue02;
		// padding: 20px 20px;
		// border-top: 1px solid $blue01;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin: 0 0 4rem;

		@include sm-gt {
			.post-type-column & {
				flex-direction: column;
			}
		}

		li {
			color: #000;
			margin: 0 0 .7em;
			@include ffz(15, 14);
			letter-spacing: .05em;
			width: calc( calc(100% / 3) - .5em );

			
			@include sm-gt {
				.post-type-column & {
					width: 100%;
					margin: 0 0 0.5em;
				}

				&:not(:nth-child(3n+1)) {
					margin-left: 0.6em;

					.post-type-column & {
						margin-left: 0;
					}
				}
			}

			@include sm-lte {
				width: calc( calc(100% / 2) - .25em );
				margin: 0 0 0.5em;

				&:not(:nth-child(2n+1)) {
					margin-left: .5em;
				}
			}

			&:last-child {
				border-bottom: 0;
			}

			&.parents_page {
				border: 0;
			}

			a {
				display: block;
				// background: $blue02;
				color: #000;
				padding: 1em 2.5em 1em 1em;
				position: relative;
				background: #fff;
				border-radius: 10px;
				line-height: 1.3;
				height: 100%;
				display: flex;
				// flex-direction: column;
				align-items: center;
				justify-content: flex-start;


				@include sm-gt {
					.post-type-column & {
						background: #f1f1f1;
						padding: 1.2em 2.5em 1.2em 1.2em;
					}
				}

				@include xs {
					padding: 1em 1.5em 1em 1em;
				}

				span.count {
					// display: none;
					background: #eee;
					padding: 3px 7px;
					border-radius: 100%;
					@include ff_montserrat;
					@include fz(10);
					margin-left: 5px;

					.column & {
						background: #fff;
					}
				}

				&:after {
					content: "\f3d1";
					font-family: $icon;
					color: #ccc;
					position: absolute;
					top: 50%;
					right: 2em;
					-webkit-transform: translateY(-50%);
					transform: translateY(-50%);
					@include xs {
						right: 1em;
					}
				}

				&.blank {
					&::after {
						content: "";
					}
				}

			}

			&.current,
			&.current_page_item {
				a {
					font-weight: bold;
					&:before {
						color: #000000;
					}
				}
			}
			
			ul {
				margin: 1em 0 0;

				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				flex-direction: column;
				align-items: center;

				@include sm {
					flex-direction: row;
				}


				li {
					padding-left: 20px;
					border-color: #e8e8e8;
					border: 0;
					// max-width: 25%;
					margin-bottom: 0;
					width: 100%;

					@include sm {
						max-width: 50%;
						width: 50%;
					}

					@include xs {
						padding-left: 2em;
						width: 100%;
						max-width: 100%;
						border: solid #ccc;
						border-width: 0 0 1px;
					}

					// &:last-child {
					// 	border: 0;
					// }
					a {
						padding: .6em 1em .6em 1.5em;
						background: transparent;
						// color: #000;
						&:after {
							display: none;
						}
						&:before {
							content: "\f3d1";
							font-family: $icon;
							color:#aaa;
							position: absolute;
							top: 50%;
							left: 0;
							-webkit-transform: translateY(-50%);
							transform: translateY(-50%);
							@include xs {
								// left: 20px;
							}
						}
					}
				}
			}
		}
	}

	.archive_block {

		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;

			li {
				// border-bottom: 1px solid #ccc;
				width: calc( calc(100% / 6) - .5em );

				@include sm-gt {
					&:not(:nth-child(6n+1)) {
						margin-left: 0.6em;
					}
				}

				@include xs {
					width: calc( calc(100% / 2) - .25em );
					margin: 0 0 0.5em;

					&:not(:nth-child(2n+1)) {
						margin-left: .5em;
					}
				}


				a {
					span {
						display: block;
						@include fz(10);
						@include ff_montserrat;
					}
				}
			}
		}
	}

	.bnr_block {
		ul {
			@include xs {
				display: flex;
				justify-content: center;
			}
			li {
				padding: 0;
				border: 0;
				margin-bottom: 10px;
				vertical-align: bottom;
				a {
					background: transparent;
					padding: 0;
					&:after {
						display: none;
					}
				}

				@include xs {
					text-align: center;
					width: 48%;
				}
			}
		}
	}

	.sidebar-sticky {
		width: 90%;
		position: sticky;
		top: 100px;
		margin: 0 0 8em;

		@include sm-lte {
			width: 100%;
			margin: 0 0 4em;
			position: initial;
		}
	}

	.sidebar-banner {
		&__wrapper {
			margin: 0 0 3em;

			@include sm-lte {
				width: 90%;
				margin: 0 auto 3em;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
			}
		}

		&__stack {
			margin: 0 0 1.5em;

			@include sm-lte {
				width: 48.5%;
				margin: 0 0 1em;
			}
		}
	}
}