/* page_ttl ---------------------- */
.page_ttl {
	// width: 1400px;
	margin: 0 auto 30px;
	background: #343d4c url(../img/common/cor_hero_image.jpg) no-repeat center top / cover;

	@include xs {
		background-size: auto 100%;
	}

	.page_ttl_block {
		// width: 1000px;
		// margin: 0 auto;
		position: relative;	
		// height: 220px;	
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;

		@include xs {
			width: 100%;
			margin: 0 auto 4em;
			// height: 120px;
			height: auto;
		}
	}

	.ttl_box {
		// position: absolute;
		// bottom: 0;
		// left: 0;
		// min-width: 350px;
		// background: #fff;
		// padding: 30px 50px 0 50px;
		// text-align: left;
		// border-radius: $border-radius $border-radius 0 0;

		@include xs {
			// position: absolute;
			// top: 60%;
			// left: 50%;
			// transform: translateX(-50%);
			// min-width: auto;
			// padding: 15px;
			width: 92%;
			margin: 0 auto;
			text-align: center;
			// background: #fff;
		}

		h2 {
			@include ffz(30, 28);
			// font-weight: 500;
			@include ff_montserrat;
			color: #fff;
			letter-spacing: .1em;

			padding: 3em 0;


			span {
				margin: 1em 0 0;
				display: block;
				@include fz(12);
				letter-spacing: 0.2em;
				// font-weight: 600;
				@include xs {
					@include fz(12);
					margin: 0;
				}
			}

			
			// &::after {
			// 	content: "";
			// 	display: block;
			// 	height: 1px;
			// 	width: 100px;
			// 	background: #000000;
			// 	margin-top: 30px;
			// 	@include xs {
			// 		margin: 15px auto 0 ;
			// 	}
			// }
		}
	}

}