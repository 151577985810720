//----------------------------------------------------
/*! Type: YakuHanMP - Based on Noto Serif CJK JP */
//----------------------------------------------------

$yakuhanmp-font-family: "YakuHanMP" !default;
$yakuhanmp-font-file: "YakuHanMP" !default;
$yakuhanmp-font-dir: "../font" !default;
$yakuhanmp-swap-use: true !default;
$yakuhanmp-eot-use: true !default;
$yakuhanmp-cdn-use: false !default;
$yakuhanmp-cdn-version: "3.3.1" !default;
$yakuhanmp-unicode-range-use: true !default;
$yakuhanmp-unicode: U+3001-3002, U+300a-3011, U+3014-3015, U+30fb, U+ff01,
  U+ff08-ff09, U+ff1a-ff1b, U+ff1f, U+ff3b, U+ff3d, U+ff5b, U+ff5d !default;
$yakuhanmp-weight: (
  100: "ExtraLight",
  200: "Light",
  300: "Regular",
  400: "Medium",
  500: "SemiBold",
  700: "Bold",
  900: "Black"
) !default;

@each $key, $value in $yakuhanmp-weight {
  @font-face {
    $family: $yakuhanmp-font-family;
    $file: $yakuhanmp-font-file;
    $dir: $yakuhanmp-font-dir;
    $swap: $yakuhanmp-swap-use;
    $eot: $yakuhanmp-eot-use;
    $cdn: $yakuhanmp-cdn-use;
    $version: $yakuhanmp-cdn-version;
    $jsdelivr: "https://cdn.jsdelivr.net/npm/yakuhanjp";
    $unicode-range: $yakuhanmp-unicode-range-use;
    $unicode: $yakuhanmp-unicode;

    font-family: $family;
    font-style: normal;
    font-weight: $key;

    @if $swap == true {
      font-display: swap;
    }

    @if $eot == true and $cdn == true {
      src: url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.eot");
    }

    @if $eot == true and $cdn == false {
      src: url("#{$dir}/#{$file}/#{$file}-#{$value}.eot");
    }

    @if $cdn == true {
      src: url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.woff2")
          format("woff2"),
        url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.woff")
          format("woff");
    } @else {
      src: url("#{$dir}/#{$file}/#{$file}-#{$value}.woff2") format("woff2"),
        url("#{$dir}/#{$file}/#{$file}-#{$value}.woff") format("woff");
    }

    @if $unicode-range == true {
      unicode-range: $unicode;
    }
  }
}
