//----------------------------------------------------
/*! Type: YakuHanJP_Noto - Based on Noto Sans CJK JP */
//----------------------------------------------------

$yakuhanjp-noto-font-family: "YakuHanJP_Noto" !default;
$yakuhanjp-noto-font-file: "YakuHanJP" !default;
$yakuhanjp-noto-font-dir: "../font" !default;
$yakuhanjp-noto-swap-use: true !default;
$yakuhanjp-noto-eot-use: true !default;
$yakuhanjp-noto-cdn-use: false !default;
$yakuhanjp-noto-cdn-version: "3.3.1" !default;
$yakuhanjp-noto-unicode-range-use: true !default;
$yakuhanjp-noto-unicode: U+3001-3002, U+3008-3011, U+3014-3015, U+30fb, U+ff01,
  U+ff08-ff09, U+ff1a-ff1b, U+ff1f, U+ff3b, U+ff3d, U+ff5b, U+ff5d !default;
$yakuhanjp-noto-weight: (
  100: "Thin",
  300: "Light",
  400: "Regular",
  500: "Medium",
  700: "Bold",
  900: "Black"
) !default;

@each $key, $value in $yakuhanjp-noto-weight {
  @font-face {
    $family: $yakuhanjp-noto-font-family;
    $file: $yakuhanjp-noto-font-file;
    $dir: $yakuhanjp-noto-font-dir;
    $swap: $yakuhanjp-noto-swap-use;
    $eot: $yakuhanjp-noto-eot-use;
    $cdn: $yakuhanjp-noto-cdn-use;
    $version: $yakuhanjp-noto-cdn-version;
    $jsdelivr: "https://cdn.jsdelivr.net/npm/yakuhanjp";
    $unicode-range: $yakuhanjp-noto-unicode-range-use;
    $unicode: $yakuhanjp-noto-unicode;

    font-family: $family;
    font-style: normal;
    font-weight: $key;

    @if $swap == true {
      font-display: swap;
    }

    @if $eot == true and $cdn == true {
      src: url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.eot");
    }

    @if $eot == true and $cdn == false {
      src: url("#{$dir}/#{$file}/#{$file}-#{$value}.eot");
    }

    @if $cdn == true {
      src: url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.woff2")
          format("woff2"),
        url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.woff")
          format("woff");
    } @else {
      src: url("#{$dir}/#{$file}/#{$file}-#{$value}.woff2") format("woff2"),
        url("#{$dir}/#{$file}/#{$file}-#{$value}.woff") format("woff");
    }

    @if $unicode-range == true {
      unicode-range: $unicode;
    }
  }
}
